import { SelectInput } from '@frontend/basic-forms';
import React from 'react';
import { ActionMeta } from 'react-select';

import useConstraintTypeSelect from './constraint-type-select.controller';

const ID = 'constraint-type-select';
export interface ConstraintTypeSelectProps {
    id?: string;
    label?: string;
    className?: string;
    required: boolean;
    submitted: boolean;
    onChange: (newValue: { value: string; label: any }, actionMeta: ActionMeta<any>) => void;
    value?: string;
}

export const ConstraintTypeSelect = (props: ConstraintTypeSelectProps) => {
    const viewProps = useConstraintTypeSelect();

    return (
        <SelectInput
            id={props.id || ID}
            className={props.className}
            label={props.label || 'Constraint type'}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={props.value}
            onChange={(value, actionMeta) => props.onChange(value as { value: string; label: any }, actionMeta)}
        />
    );
};
