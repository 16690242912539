import { Transaction, transactionRequest } from '@frontend/transaction';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../redux/store';
import { TransactionDetailModalWrapperProps } from './transaction-detail-modal-wrapper.component';

interface ViewProps {
    transaction?: Transaction;
}

const useTransactionDetailModalWrapper = (props: TransactionDetailModalWrapperProps): ViewProps => {
    const transactions = useAppSelector((state) => state.transactions);
    const [transaction, changeTransaction] = useState<Transaction | undefined>(undefined);

    useEffect(() => {
        const found = transactions.unordered.find((t) => t.id === props.id);
        if (found) changeTransaction(found);
        else transactionRequest(props.id);
    }, [props.id, transactions]);

    return {
        transaction
    };
};

export default useTransactionDetailModalWrapper;
