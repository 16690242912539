import { EmailInput, PasswordInput } from '@frontend/basic-forms';

import logo from '../../../assets/images/telloport_logo_horizontal.png';
import useBasicLogin from './basic-login.controller';

export const ID = 'basic-login';
export interface Props {
    callback?: () => void;
}

const BasicLogin = (props: Props) => {
    const viewProps = useBasicLogin(props);

    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-center align-items-center m-5'>
            <form
                className='card w-50'
                onSubmit={viewProps.onSubmit}>
                <div className='card-header text-center'>
                    <img
                        src={logo}
                        className='img-fluid w-25'
                    />
                    <h4 className='m-3'>Sign in</h4>
                </div>
                <div className='card-body d-flex flex-column align-items-center w-100'>
                    <EmailInput
                        className='w-100'
                        required
                        label='Email'
                        errorMessage='Please fill in a valid email.'
                        autoFocus
                        value={viewProps.formValues.email}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, email: value })}
                        isValidCallback={(value) => viewProps.changeEmailValid(value)}
                        submitted={viewProps.submitted}
                    />
                    <PasswordInput
                        className='w-100'
                        required
                        label='Password'
                        errorMessage='Please fill in your password.'
                        value={viewProps.formValues.password}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, password: value })}
                        isValidCallback={(value) => viewProps.changePasswordValid(value)}
                    />
                </div>
                <div className='card-footer text-center'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        disabled={!viewProps.emailValid || !viewProps.passwordValid || viewProps.submitted}>
                        Sign in
                    </button>
                </div>
            </form>
        </div>
    );
};

export default BasicLogin;
