import { convertLanguage } from '@frontend/common';
import { Contact } from '@frontend/contact';
import { DateBadge, EmailBadge, NameBadge, ObjectField, PhoneNumberBadge } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';

import AccountBadge from '../../badges/account-badge/account-badge.component';
import UserBadge from '../../badges/user-badge/user-badge.component';
import useContactDetail from './contact-detail.controller';

export interface ContactDetailProps {
    contact: Contact;
}

export const ContactDetail = (props: ContactDetailProps) => {
    const viewProps = useContactDetail();
    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column w-100 h-100'>
                <ObjectField
                    fieldName={CommonMessage.FORMS.ALIAS}
                    value={props.contact.alias}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.NAME}
                    value={
                        <NameBadge
                            firstName={props.contact.first_name}
                            lastName={props.contact.last_name}
                        />
                    }
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.LANGUAGE}
                    value={convertLanguage(props.contact.language)}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.MOBILE}
                    value={<PhoneNumberBadge phone={props.contact.mobile} />}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.EMAIL()}
                    value={<EmailBadge email={props.contact.email} />}
                />
                <ObjectField
                    fieldName='Type'
                    value={props.contact.type}
                />
                {viewProps.isDetailPage && (
                    <>
                        <ObjectField
                            fieldName='Account'
                            value={<AccountBadge id={props.contact.account_id} />}
                        />
                        <ObjectField
                            fieldName='User'
                            value={<UserBadge id={props.contact.user_id} />}
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.contact.creation_timestamp}
                                    displayTime
                                />
                            }
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.contact.update_timestamp}
                                    displayTime
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
};
