import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateUser, UpdateUser, User, UserListResponse } from '../user';

const endpoint = '/user-service/api/v1';

export class UserClient extends APIClient {
    public static async fetchUsers(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<UserListResponse> {
        return await this.apiPaginated<UserListResponse, DefaultQueryParams>(`${endpoint}/users`, queryParams);
    }

    public static async postUser(user: CreateUser): Promise<User> {
        const response = await this.post(`${endpoint}/users`, user);
        return await this.handleResponse<User>(response);
    }

    public static async fetchUser(userId: string): Promise<User> {
        const response = await this.fetch(`${endpoint}/users/${userId}`);
        return await this.handleResponse<User>(response);
    }

    public static async patchUser(user: UpdateUser, userId: string): Promise<User> {
        const response = await this.patch(`${endpoint}/users/${userId}`, user);
        return await this.handleResponse<User>(response);
    }

    public static async deleteUser(userId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/users/${userId}`);
        return await this.handleVoidResponse(response);
    }
    public static async resolveUsers(userIds: string[]): Promise<UserListResponse> {
        const response = await this.post(`${endpoint}/users-resolve`, { ids: userIds });
        return await this.handleResponse<UserListResponse>(response);
    }
}
