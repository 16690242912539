import { EventMessage } from '@frontend/event-service';
import { useMemo } from 'react';
import { Column } from 'react-table';

import EventColumns from './event-columns';
import { EventTableProps } from './event-table.component';

interface ViewProps {
    columns: Column<EventMessage>[];
    pageCount: number;
}

const useEventTable = (props: EventTableProps): ViewProps => {
    const columns = useMemo(() => EventColumns, []);

    const pageCount = useMemo(() => {
        if (!props.data) return 1;
        else return Math.ceil(props.data.length / props.pageSize);
    }, [props.data, props.pageSize]);

    return {
        columns,
        pageCount
    };
};

export default useEventTable;
