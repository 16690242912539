import React from 'react';

import useDateBadge from './date-badge.controller';

export interface DateBadgeProps {
    date?: Date | string;
    displayTime?: boolean;
}

export const DateBadge = (props: DateBadgeProps) => {
    const viewProps = useDateBadge(props);
    return (
        <span>
            <>
                {viewProps.date}
                {props.displayTime && (
                    <>
                        <br />
                        {viewProps.time}
                    </>
                )}
            </>
        </span>
    );
};
