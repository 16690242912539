import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { TransactionClient } from '../api/transaction-client';
import { Transaction, TransactionListResponse } from '../transaction';

export interface TransactionSliceState {
    unordered: Transaction[];
    transactions: TransactionListResponse | null;
    status: SliceStatus;
}

const initialState: TransactionSliceState = {
    unordered: [],
    transactions: null,
    status: SliceStatus.INIT
};

export const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        seedTransactions(state, action: PayloadAction<Transaction[]>) {
            state.unordered = [...state.unordered.filter((transaction) => action.payload.find((t) => t.id == transaction.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.transactions == null) {
                    state.transactions = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.transactions.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.transactions.results.length !== action.payload.count) {
                        state.transactions.count = action.payload.count;
                        state.transactions.results = new Array(action.payload.count);
                    }
                    state.transactions.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((account) => action.payload.results.find((a) => a.id == account.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteSpotTransaction.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteSpotTransaction.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.transactions == null) return;
                const found = state.transactions.results.find((t) => t.id == action.meta.arg.transactionId);
                if (found == undefined) return;
                state.transactions.results.splice(state.transactions.results.indexOf(found), 1);
            });
    }
});

export const fetchTransactions = createAsyncThunk<TransactionListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchTransactions',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await TransactionClient.fetchTransactions(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteSpotTransaction = createAsyncThunk<void, { spotId: string; transactionId: string }>(
    'deleteSpotTransaction',
    async (variables: { spotId: string; transactionId: string }, { rejectWithValue }) => {
        try {
            return await TransactionClient.deleteSpotTransaction(variables.spotId, variables.transactionId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const { seedTransactions } = transactionSlice.actions;
