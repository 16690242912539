import { DefaultModalProps, LoadingModal, ModalType } from '@frontend/elements';

import SlotDetailModal from '../slot-detail-modal/slot-detail-modal.component';
import useSlotDetailModalWrapper from './slot-detail-modal-wrapper.controller';

export interface SlotDetailModalWrapperProps extends DefaultModalProps {
    id: string;
}

const SlotDetailModalWrapper = (props: SlotDetailModalWrapperProps): JSX.Element => {
    const viewProps = useSlotDetailModalWrapper(props);

    if (!viewProps.slot) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <SlotDetailModal
            slot={viewProps.slot}
            customWidth={40}
            {...props}
        />
    );
};

export default SlotDetailModalWrapper;
