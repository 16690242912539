import { getCookie, setCookie } from '@frontend/common';
import { en, fr, nl } from '@frontend/lang';
import { useEffect, useState } from 'react';
import { MessageFormatElement } from 'react-intl';

import * as enLocal from './en.json';
import * as frLocal from './fr.json';
import * as nlLocal from './nl.json';

const useLocale = () => {
    const [messages, changeMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]>>(en);
    const [locale, changeLocale] = useState<Locale>();
    const defaultBrowserLanguage = getCorrectLocale(window.navigator.language);
    const previouslySelectedLanguage = getCookie('language');

    useEffect(() => {
        if (previouslySelectedLanguage !== undefined) {
            changeLocale(getCorrectLocale(previouslySelectedLanguage.toString()));
        } else {
            changeLocale(getCorrectLocale(defaultBrowserLanguage.toString()));
        }
    }, []);

    useEffect(() => {
        if (locale) {
            setCookie('language', locale.valueOf(), 365);
            changeMessages(messagesMap.get(locale));
        }
    }, [locale]);

    return { messages, locale, changeLocale } as const;
};
export default useLocale;

export function getCorrectLocale(lang: string): Locale {
    switch (lang) {
        case 'en':
        case 'en-US':
            return Locale.ENGLISH;
        case 'nl-NL':
        case 'nl-BE':
        case 'nl':
            return Locale.DUTCH;
        case 'fr':
        case 'fr-FR':
        case 'fr-BE':
            return Locale.FRENCH;
        default:
            return Locale.ENGLISH;
    }
}

export enum Locale {
    ENGLISH = 'en-US',
    DUTCH = 'nl-BE',
    FRENCH = 'fr-BE'
}

export function getLocaleStringRepresentation(locale: Locale): string {
    switch (locale) {
        case Locale.DUTCH:
            return 'Nederlands';
        case Locale.ENGLISH:
            return 'English';
        case Locale.FRENCH:
            return 'Français';
    }
}

const messagesMap = new Map<Locale, any>();
messagesMap.set(Locale.ENGLISH, { ...en, ...enLocal });
messagesMap.set(Locale.DUTCH, { ...nl, ...nlLocal });
messagesMap.set(Locale.FRENCH, { ...fr, ...frLocal });
