export enum ConstraintType {
    ACCOUNT = 'account',
    WORKFLOW = 'workflow',
    DIMENSION = 'dimension',
    OCCUPANCY = 'occupancy',
    PRODUCT = 'product',
    COUNT = 'count'
}

export type ConstraintData = DimensionConstraintData | ProductConstraintData | CountConstraintData | AccountConstraintData | WorkflowConstraintData;

export const isAccountConstraintData = (constraintData: any): constraintData is AccountConstraintData => {
    return (constraintData as AccountConstraintData).account_id !== undefined && (constraintData as AccountConstraintData).percentage !== undefined;
};

export interface AccountConstraintData {
    account_id?: string;
    percentage?: number;
}

export const isWorkflowConstraintData = (constraintData: any): constraintData is WorkflowConstraintData => {
    return (constraintData as WorkflowConstraintData).account_id !== undefined && (constraintData as WorkflowConstraintData).workflow_id !== undefined;
};

export interface WorkflowConstraintData {
    account_id?: string;
    workflow_id?: string;
}

export const isDimensionConstraintData = (constraintData: any): constraintData is DimensionConstraintData => {
    return (
        (constraintData as DimensionConstraintData).height !== undefined &&
        (constraintData as DimensionConstraintData).width !== undefined &&
        (constraintData as DimensionConstraintData).depth !== undefined
    );
};
export interface DimensionConstraintData {
    height?: number;
    width?: number;
    depth?: number;
}

export const isProductConstraintData = (constraintData: any): constraintData is ProductConstraintData => {
    return (constraintData as ProductConstraintData).type !== undefined && (constraintData as ProductConstraintData).product_id !== undefined;
};

export interface ProductConstraintData {
    type?: string | null;
    product_id?: string | null;
}

export const isCountConstraintData = (constraintData: any): constraintData is CountConstraintData => {
    return (constraintData as CountConstraintData).count !== undefined;
};

export interface CountConstraintData {
    count?: number;
}
