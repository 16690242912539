import { slotRequest } from '@frontend/slot';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../redux/store';
import { SlotBadgeProps } from './slot-badge.component';

interface ViewProps {
    displayName?: string | null;
    disabled: boolean;
}

const useSlotBadge = (props: SlotBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const slotState = useAppSelector((state) => state.slots);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | null | undefined>(props.name);

    useEffect(() => {
        if (props.id == null) {
            changeDisabled(true);
            changeDisplayName('None');
        } else if (displayName == undefined) {
            const found = slotState.unordered.find((s) => s.id == props.id);
            if (found) {
                changeDisplayName(found.number || found.index);
            } else if (requested == false) {
                changeRequested(true);
                slotRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        }
    }, [slotState]);

    return {
        displayName,
        disabled
    };
};

export default useSlotBadge;
