import AccountSelect from '../account-select/account-select.component';
import ContactSelect from '../contact-select/contact-select.component';
import RakindaSelect from '../rakinda-select/rakinda-select.component';
import SpotSelect from '../spot-select/spot-select.component';
import TerminalSelect from '../terminal-select/terminal-select.component';
import TransactionSelect from '../transaction-select/transaction-select.component';
import UserSelect from '../user-select/user-select.component';

const ID = 'role-asset-select';
export interface EntityByTypeSelectProps {
    required?: boolean;
    submitted: boolean;
    type: string;
    onChange: (value: string) => void;
    value: any;
}

const EntityByTypeSelect = (props: EntityByTypeSelectProps) => {
    return (
        <div id={ID}>
            {props.type === 'account' && (
                <AccountSelect
                    required
                    label='Item (account)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )}

            {props.type === 'contact' && (
                <ContactSelect
                    required
                    label='Item (contact)'
                    submitted={props.submitted}
                    value={props.value}
                    onChange={(val) => props.onChange(val || '')}
                />
            )}

            {props.type === 'spot' && (
                <SpotSelect
                    required
                    label='Item (spot)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )}

            {props.type === 'user' && (
                <UserSelect
                    required
                    label='Item (user)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )}

            {props.type === 'rakinda' && (
                <RakindaSelect
                    required
                    label='Item (rakinda)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )}
            {props.type === 'terminal' && (
                <TerminalSelect
                    required
                    label='Item (terminal)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )}
            {props.type === 'transaction' && (
                <TransactionSelect
                    required
                    label='Item (transaction)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )}
        </div>
    );
};

export default EntityByTypeSelect;
