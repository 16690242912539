import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateWorkflowButton, UpdateWorkflowButton, WorkflowButton, WorkflowButtonListResponse } from '../workflow-button';

const endpoint = '/workflow-service/api/v1';
export class WorkflowButtonClient extends APIClient {
    public static async fetchWorkflowButtons(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<WorkflowButtonListResponse> {
        return await this.apiPaginated<WorkflowButtonListResponse, any>(`${endpoint}/workflow-buttons`, queryParams);
    }

    public static async fetchUserWorkflowButtons(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<WorkflowButtonListResponse> {
        return await this.apiPaginated<WorkflowButtonListResponse, any>(`${endpoint}/user-workflow-buttons`, queryParams);
    }

    public static async fetchAccountWorkflowButtons(
        accountId: string,
        workflowId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<WorkflowButtonListResponse> {
        return await this.apiPaginated(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/buttons`, queryParams);
    }

    public static async postAccountWorkflowButton(workflowButton: CreateWorkflowButton, accountId: string, workflowId: string): Promise<WorkflowButton> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/buttons`, workflowButton);
        return await this.handleResponse<WorkflowButton>(response);
    }

    public static async fetchAccountWorkflowButton(accountId: string, workflowId: string, workflowButtonId: string): Promise<WorkflowButton> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/buttons/${workflowButtonId}`);
        return await this.handleResponse<WorkflowButton>(response);
    }

    public static async patchAccountWorkflowButton(
        workflowButton: UpdateWorkflowButton,
        accountId: string,
        workflowId: string,
        workflowButtonId: string
    ): Promise<WorkflowButton> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/buttons/${workflowButtonId}`, workflowButton);
        return await this.handleResponse<WorkflowButton>(response);
    }

    public static async deleteAccountWorkflowButton(accountId: string, workflowId: string, workflowButtonId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/buttons/${workflowButtonId}`);
        return await this.handleVoidResponse(response);
    }
}
