import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { UserClient } from '../api/user-client';
import { User, UserListResponse } from '../user';

interface UserState {
    unordered: User[];
    users: UserListResponse | null;
    status: SliceStatus;
}

const initialState: UserState = {
    unordered: [],
    users: null,
    status: SliceStatus.INIT
};

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        seedUsers(state, action: PayloadAction<User[]>) {
            state.unordered = [...state.unordered.filter((user) => action.payload.find((u) => u.id == user.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.users == null) {
                    state.users = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.users.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.users.results.length !== action.payload.count) {
                        state.users.count = action.payload.count;
                        state.users.results = new Array(action.payload.count);
                    }
                    state.users.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((user) => action.payload.results.find((u) => u.id == user.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                if (state.users) {
                    const found = state.users.results.find((u) => u.id == action.meta.arg);
                    if (found) {
                        state.users.results.splice(state.users.results.indexOf(found), 1, action.payload);
                    } else state.users.results.push(action.payload);
                }
                state.unordered = [...state.unordered.filter((user) => action.payload.id !== user.id), action.payload];
            })
            .addCase(deleteUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.users == null) return;
                const found = state.users.results.find((u) => u.id == action.meta.arg);
                if (found == undefined) return;
                state.users.results.splice(state.users.results.indexOf(found), 1);
            });
    }
});

export const fetchUsers = createAsyncThunk<UserListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchUsers',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await UserClient.fetchUsers(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchUser = createAsyncThunk<User, string>('fetchUser', async (userId: string, { rejectWithValue }) => {
    try {
        return await UserClient.fetchUser(userId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteUser = createAsyncThunk<void, string>('deleteUser', async (userId: string, { rejectWithValue }) => {
    try {
        return await UserClient.deleteUser(userId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const { seedUsers } = userSlice.actions;
