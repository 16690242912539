import { Badge } from '@frontend/badge';
import { ClassType } from '@frontend/common';
import { DefaultModal, DefaultModalProps, ModalFooter, ModalHeader, ModalType } from '@frontend/elements';
import { FaEye } from 'react-icons/fa';

import BadgeDetail from '../badge-detail.component';
import { useBadgeDetailModal } from './badge-detail-modal.controller';

const ID = 'badge-detail-modal';
export interface BadgeDetailModalProps extends DefaultModalProps {
    badge: Badge;
}

const BadgeDetailModal = (props: BadgeDetailModalProps) => {
    const viewProps = useBadgeDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id={ID + '-header'}
                handleClose={props.handleClose}
                title={props.badge.number}
            />
            <div className='modal-body'>
                <BadgeDetail badge={props.badge} />
            </div>
            <ModalFooter
                buttons={[
                    {
                        text: 'View details',
                        onClick: () => viewProps.viewDetails(),
                        icon: FaEye,
                        type: ClassType.PRIMARY
                    }
                ]}
            />
        </DefaultModal>
    );
};

export default BadgeDetailModal;
