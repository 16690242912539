import { SelectInput } from '@frontend/basic-forms';
import React from 'react';
import { ActionMeta } from 'react-select';

import { WorkflowButtonType } from '../../workflow-button';
import useWorkflowButtonTypeSelect from './workflow-button-type-select.controller';

const ID = 'workflow-button-type-select';
export interface WorkflowButtonTypeSelectProps {
    required: boolean;
    submitted: boolean;
    onChange: (newValue: { value: WorkflowButtonType; label: any }, actionMeta: ActionMeta<any>) => void;
    value?: string;
}

export const WorkflowButtonTypeSelect = (props: WorkflowButtonTypeSelectProps) => {
    const viewProps = useWorkflowButtonTypeSelect();

    return (
        <div id={ID}>
            <SelectInput
                label={'Action'}
                submitted={props.submitted}
                required={props.required}
                options={viewProps.options}
                value={props.value}
                onChange={(value, actionMeta) => props.onChange(value as { value: WorkflowButtonType; label: any }, actionMeta)}
            />
        </div>
    );
};
