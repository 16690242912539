import React from 'react';
import { HiOutlineMail } from 'react-icons/hi';

export interface EmailBadgeProps {
    email?: string;
}

export function EmailBadge(props: EmailBadgeProps) {
    if (props.email === undefined || props.email === null || props.email === '') return <>-</>;
    return (
        <a href={'mailto:' + props.email}>
            <span className='d-flex flex-row align-items-center'>
                <span className='text-info me-1'>
                    <HiOutlineMail />
                </span>
                <span className='text-xs'>{props.email}</span>
            </span>
        </a>
    );
}
