import { User, UserClient, fetchUsers } from '@frontend/user';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { UserSelectProps } from './user-select.component';

interface ViewProps {
    options: { value: string; label: string }[];
    value?: { value: string; label: string };
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;
}

const pageSize = '100';
const useUserSelect = (props: UserSelectProps): ViewProps => {
    const dispatch = useAppDispatch();
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const userState = useAppSelector((state) => state.users);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const value = useMemo(() => {
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options]);

    useEffect(() => {
        dispatch(fetchUsers({ index: index.toString(), size: pageSize }));
    }, [index]);

    useEffect(() => {
        if (userState.users) {
            changeCount(userState.users.count);
            changeOptions(mapResultSetToOptions(userState.users.results));
        }
    }, [userState.users]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            UserClient.fetchUsers({ search: inputValue, index: '0', size: pageSize }).then((result) => {
                callback(mapResultSetToOptions(result.results));
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    return {
        options,
        value,
        onScrollToBottom,
        search
    };
};

function mapResultSetToOptions(users: User[]): { value: any; label: string }[] {
    return users.map((u) => ({ value: u.id, label: u.email }));
}

export default useUserSelect;
