import { Logger } from '@frontend/Logger';

import { AuthenticationError, AuthenticationSuccessResponse } from './models';

const refreshEndpoint = '/authorization-service/api/v1/refresh';
export default class BasicAuthenticationClient {
    static endpoint = '/authentication-service/api/v1';
    static async login(email: string, password: string): Promise<AuthenticationSuccessResponse> {
        Logger.debug('Sending login request.');
        let signInUri = process.env['NX_API_URL'] + this.endpoint + '/basic-auth-sign-in';
        if (process.env['NX_BUILD_ENV'] === 'azure' || process.env['NX_BUILD_ENV'] === 'azure.production') {
            signInUri = 'https://' + this.endpoint.split('/')[1] + process.env['NX_API_URL'] + this.endpoint + '/basic-auth-sign-in';
        }
        const response = await fetch(signInUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: `${email}`,
                password: `${password}`
            })
        });
        Logger.debug('Login response code: ' + response.status);
        if (response.status !== 200) {
            throw new AuthenticationError('Login failed.', await response.json());
        }
        return response.json();
    }

    static async refresh(refreshToken: string): Promise<AuthenticationSuccessResponse> {
        Logger.debug('Sending refresh token request.');
        let refreshUri = process.env['NX_API_URL'] + refreshEndpoint;
        if (process.env['NX_BUILD_ENV'] === 'azure' || process.env['NX_BUILD_ENV'] === 'azure.production') {
            refreshUri = 'https://' + refreshEndpoint.split('/')[1] + process.env['NX_API_URL'] + refreshEndpoint;
        }
        const response = await fetch(refreshUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refresh_token: `${refreshToken}`
            })
        });
        Logger.debug('Token refresh response code: ' + response.status);
        return response.json();
    }
}
