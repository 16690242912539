import React, { createContext, useContext, useEffect, useState } from 'react';

import { AuthorizationClient } from '../../api/authorization-client';
import { UserPermissions } from '../../permission';

const AuthorizationContext = createContext({
    permissions: {} as UserPermissions | null,
    requested: (): void => {}
});

export const AuthorizationProvider = ({ children }: any) => {
    const [permissions, changePermissions] = useState<UserPermissions | null>(null);
    const [lastUpdate, changeLastUpdate] = useState<number>(0);

    useEffect(() => {
        requested();
    }, []);

    const requested = (): void => {
        if (Date.now() - lastUpdate > 60 * 1000)
            AuthorizationClient.fetchPermissions()
                .then((result) => {
                    changePermissions(result);
                    changeLastUpdate(Date.now());
                })
                .catch((err) => {
                    console.log(err);
                });
    };

    return <AuthorizationContext.Provider value={{ permissions, requested }}>{children}</AuthorizationContext.Provider>;
};

export const useAuthorization = () => {
    const context = useContext(AuthorizationContext);
    if (!context) {
        throw new Error('useAuthorization must be used within an AuthorizationProvider');
    }
    return context;
};
