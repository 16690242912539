import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Buttons = {
    CANCEL: (
        <FormattedMessage
            id='Buttons.CANCEL'
            description='Cancel text on a button'
            defaultMessage='Cancel'
        />
    ),
    SUBMIT: (
        <FormattedMessage
            id='Buttons.SUBMIT'
            description='Submit text on a button'
            defaultMessage='Submit'
        />
    ),
    DELETE: (
        <FormattedMessage
            id='Buttons.DELETE'
            description='Delete text on a button'
            defaultMessage='Delete'
        />
    ),
    UPDATE: (
        <FormattedMessage
            id='Buttons.UPDATE'
            description='Update text on a button'
            defaultMessage='Update'
        />
    ),
    OPEN: (
        <FormattedMessage
            id='Buttons.OPEN'
            description='Open text on a button'
            defaultMessage='Open'
        />
    )
};
