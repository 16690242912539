import { DateBadge, ObjectField, SpotBadge } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Slot } from '@frontend/slot';

import useSlotDetail from './slot-detail.controller';

export interface SlotDetailProps {
    slot: Slot;
}

export const SlotDetail = (props: SlotDetailProps) => {
    const viewProps = useSlotDetail(props);

    //TODO: Add Module badge
    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column w-100 h-100'>
                <ObjectField
                    fieldName={CommonMessage.FORMS.NAME}
                    value={props.slot.number}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.SLOT_NUMBER}
                    value={props.slot.number}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.SPOT}
                    value={
                        <SpotBadge
                            id={props.slot.spot_id}
                            name={viewProps.spot?.name}
                        />
                    }
                />
                {viewProps.isDetailPage && (
                    <>
                        <ObjectField
                            fieldName='Display'
                            value={props.slot.display}
                        />
                        <ObjectField
                            fieldName='Location'
                            value={props.slot.location && Object.entries(props.slot.location).map((v) => `${v[0]}:${v[1]}`)}
                        />
                        <ObjectField
                            fieldName='Dimension'
                            value={props.slot.dimension && Object.entries(props.slot.dimension).map((v) => `${v[0]}:${v[1]}`)}
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.slot.creation_timestamp}
                                    displayTime
                                />
                            }
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.slot.update_timestamp}
                                    displayTime
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
};
