import { ClassType } from '@frontend/common';
import { DefaultModal, DefaultModalProps, HorizontalButtonGroup, ModalHeader, ModalType } from '@frontend/elements';
import { User } from '@frontend/user';
import { FaEye } from 'react-icons/fa';

import { UserDetail } from '../user-detail.component';
import { useUserDetailModal } from './user-detail-modal.controller';

const ID = 'user-detail-modal';

export interface UserDetailModalProps extends DefaultModalProps {
    user: User;
}

const UserDetailModal = (props: UserDetailModalProps) => {
    const viewProps = useUserDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id='user-detail-modal-header'
                handleClose={props.handleClose}
                title={`${props.user.first_name} ${props.user.last_name}`}
            />
            <div className='modal-body'>
                <UserDetail user={props.user} />
            </div>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <HorizontalButtonGroup
                    direction='left'
                    buttons={[
                        {
                            text: 'View details',
                            onClick: () => viewProps.viewDetails(),
                            type: ClassType.PRIMARY,
                            icon: FaEye
                        }
                    ]}
                />
            </div>
        </DefaultModal>
    );
};

export default UserDetailModal;
