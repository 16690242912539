import { useNavigate } from 'react-router-dom';

import { BadgeDetailModalProps } from './badge-detail-modal.component';

interface ViewProps {
    viewDetails: () => void;
}

export const useBadgeDetailModal = (props: BadgeDetailModalProps): ViewProps => {
    const navigate = useNavigate();

    const viewDetails = () => {
        navigate(`/badge/${props.badge?.id}/detail`);
    };

    return {
        viewDetails
    };
};
