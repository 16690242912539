import { PaginatedResponse } from '@frontend/api-utils';

export interface Workflow {
    id: string;
    name: string;
    account_id: string;
    creation_timestamp: string;
}

export type WorkflowListResponse = PaginatedResponse<Workflow>;
export interface CreateWorkflow {
    name: string;
}

export interface CopyWorkflow {
    account_id?: string;
}

export enum SearchWorkflowQueryParams {
    PARTIAL_TEXT = 'partial_text'
}
