import { FaWindowRestore } from 'react-icons/fa';

import { DetailModalType } from '../../modals/detail-modal-container/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useSlotBadge from './slot-badge.controller';

export interface SlotBadgeProps {
    id?: string | null;
    name?: string | null;
}

const SlotBadge = (props: SlotBadgeProps) => {
    const viewProps = useSlotBadge(props);

    return (
        <BadgeTemplate
            objectId={props.id}
            type={DetailModalType.SLOT}
            disabled={viewProps.disabled}>
            <FaWindowRestore className='me-2' />
            {viewProps.displayName ? (
                viewProps.displayName
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </BadgeTemplate>
    );
};

export default SlotBadge;
