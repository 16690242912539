import { WorkflowState, workflowRequest } from '@frontend/workflow';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WorkflowBadgeProps } from './workflow-badge.component';

interface ViewProps {
    displayName?: string;
    disabled: boolean;
    onClick: () => void;
}

const useWorkflowBadge = (props: WorkflowBadgeProps): ViewProps => {
    const navigate = useNavigate();
    const [requested, changeRequested] = useState<boolean>(false);
    const workflowState: WorkflowState = useSelector((state: any) => state.workflows);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | undefined>(props.name);
    const [accountId, changeAccountId] = useState<string | undefined>(props.accountId);
    useEffect(() => {
        if (props.id != undefined) {
            const found = workflowState.unordered.find((w) => w.id == props.id);
            if (found) {
                changeDisplayName(found.name);
                changeAccountId(found.account_id);
            } else if (requested == false) {
                changeRequested(true);
                workflowRequest(props.id);
            }

            if (requested && !found) {
                changeDisplayName('Not found');
            }
        }

        if (!props.id || !accountId) changeDisabled(true);
        if (accountId) changeDisabled(false);
    }, [workflowState, props.id, accountId]);

    const onClick = () => {
        if (!props.id || !accountId) return;
        navigate(`/account/${props.accountId}/workflow/${props.id}`);
    };
    return {
        displayName,
        disabled,
        onClick
    };
};

export default useWorkflowBadge;
