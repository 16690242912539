import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateWorkflowStep, PatchWorkflowStep, WorkflowStep, WorkflowStepListResponse } from '../workflow-step';

const endpoint = '/workflow-service/api/v1';
export class WorkflowStepClient extends APIClient {
    public static async fetchWorkflowSteps(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<WorkflowStepListResponse> {
        return await this.apiPaginated<WorkflowStepListResponse, any>(`${endpoint}/workflow-steps`, queryParams);
    }

    public static async fetchAccountWorkflowSteps(
        accountId: string,
        workflowId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<WorkflowStepListResponse> {
        return await this.apiPaginated(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/steps`, queryParams);
    }

    public static async postAccountWorkflowStep(workflowStep: CreateWorkflowStep, accountId: string, workflowId: string): Promise<WorkflowStep> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/steps`, workflowStep);
        return await this.handleResponse<WorkflowStep>(response);
    }

    public static async fetchAccountWorkflowStep(accountId: string, workflowId: string, workflowStepId: string): Promise<WorkflowStep> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/steps/${workflowStepId}`);
        return await this.handleResponse<WorkflowStep>(response);
    }

    public static async patchAccountWorkflowStep(
        workflowStep: PatchWorkflowStep,
        accountId: string,
        workflowId: string,
        workflowStepId: string
    ): Promise<WorkflowStep> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/steps/${workflowStepId}`, workflowStep);
        return await this.handleResponse<WorkflowStep>(response);
    }

    public static async deleteAccountWorkflowStep(accountId: string, workflowId: string, workflowStepId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/steps/${workflowStepId}`);
        return await this.handleResponse(response);
    }
}
