import { TransactionStateName } from '@frontend/transaction';
import React from 'react';

import useTransactionStateBadge from './transaction-state-badge.controller';

const ID = 'transaction-state-badge';
export interface TransactionStateBadgeProps {
    state: TransactionStateName;
}

export const TransactionStateBadge = (props: TransactionStateBadgeProps) => {
    const viewProps = useTransactionStateBadge(props);
    return (
        <span
            id={ID}
            className={`badge badge-${viewProps.type}`}>
            {viewProps.displayName}
        </span>
    );
};
