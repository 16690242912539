import { hasAnyPermission, useAuthorization } from '@frontend/authorization';

import { Button, HorizontalButtonGroupProps } from './horizontal-button-group.component';

interface ViewProps {
    style: any;
    buttons: Button[];
}

const useHorizontalButtonGroup = (props: HorizontalButtonGroupProps): ViewProps => {
    const { permissions } = useAuthorization();
    const buttons = props.buttons.filter(
        (b) => !b.hide && ((permissions && b.requiredPermissions && hasAnyPermission(permissions, b.requiredPermissions)) || !b.requiredPermissions)
    );
    const flexDirection = props.direction === 'right' ? 'row-reverse' : 'row'; // Adjust flex direction
    const justifyContent = props.direction === 'right' ? 'flex-end' : 'flex-start'; // Adjust justify content
    const style = { whiteSpace: 'nowrap', display: props.noFlex ? '' : 'flex', flexDirection: flexDirection, justifyContent: justifyContent };
    return { style, buttons };
};

export default useHorizontalButtonGroup;
