import React from 'react';
import { LuBox } from 'react-icons/lu';

import { BadgeTemplate, DetailModalType } from '../badge-template/badge-template.component';
import { useTransactionBadge } from './transaction-badge.controller';

export interface TransactionBadgeProps {
    id?: string;
    name?: string;
}

export const TransactionBadge = (props: TransactionBadgeProps) => {
    const viewProps = useTransactionBadge(props);

    return (
        <BadgeTemplate
            disabled={viewProps.disabled}
            type={DetailModalType.TRANSACTION}
            objectId={props.id}>
            <LuBox className='me-2' />
            {viewProps.displayName ? (
                viewProps.displayName
            ) : (
                <span className='placeholder'>
                    <span className='placeholder-glow'>Loading</span>
                </span>
            )}
        </BadgeTemplate>
    );
};
