import { useEffect, useState } from 'react';

import { UserClient } from './api/user-client';
import { User } from './user';

declare global {
    interface WindowEventMap {
        'useUser:request': CustomEvent<string>;
    }
}

const REQUEST_USER_EVENT = 'useUser:request';
const STORAGE_KEY = 'CTS_REQ';

export function userRequest(userId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_USER_EVENT, { detail: userId }));
}

interface Props {
    seedStore: (users: User[]) => void;
}

export function useUsers(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(REQUEST_USER_EVENT, listener);
    }, []);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getUsers(pending).then((result) => {
                props.seedStore(result);
                changePending([]);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }

            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };
    async function getUsers(userIds: string[]): Promise<User[]> {
        const response = await UserClient.resolveUsers(userIds);
        return response.results;
    }
}
