import { Contact, fetchContact } from '@frontend/contact';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { ContactDetailModalWrapperProps } from './contact-detail-modal-wrapper.component';

interface ViewProps {
    contact?: Contact;
}

const useContactDetailModalWrapper = (props: ContactDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const contacts = useAppSelector((state) => state.contacts);
    const [contact, changeContact] = useState<Contact | undefined>(undefined);

    useEffect(() => {
        const found = contacts.unordered.find((c) => c.id === props.id);
        if (found) changeContact(found);
        else dispatch(fetchContact(props.id));
    }, [props.id, contacts]);

    return { contact };
};

export default useContactDetailModalWrapper;
