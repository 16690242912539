import { DateBadge, ObjectField } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Terminal } from '@frontend/terminal-service';

import useTerminalDetail from './terminal-detail.controller';

interface TerminalDetailProps {
    terminal: Terminal;
}

export const TerminalDetail = (props: TerminalDetailProps): JSX.Element => {
    const viewProps = useTerminalDetail();

    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column h-100 w-100'>
                <ObjectField
                    fieldName='Identifier'
                    value={props.terminal.identifier}
                />
                {viewProps.isDetailPage && (
                    <>
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.terminal.creation_timestamp}
                                    displayTime
                                />
                            }
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.terminal.update_timestamp}
                                    displayTime
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
};
