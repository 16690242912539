import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { EventEntitiesListResponse, EventListResponse } from '../event';

const endpoint = '/event-service/api/v1';
export class EventClient extends APIClient {
    public static async fetchEntityEvents(entityType: string, entityId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<EventListResponse> {
        return await this.apiPaginated<EventListResponse, string | DefaultQueryParams>(`${endpoint}/${entityType}s/${entityId}/events`, queryParams);
    }

    public static async fetchEventEntities(eventId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<EventEntitiesListResponse> {
        return await this.apiPaginated<EventEntitiesListResponse, string | DefaultQueryParams>(`${endpoint}/events/${eventId}/entities`, queryParams);
    }
}
