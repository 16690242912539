import { userRequest } from '@frontend/user';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../redux/store';
import { UserBadgeProps } from './user-badge.component';

interface ViewProps {
    displayName?: { first_name?: string; last_name?: string } | string;
    disabled: boolean;
}

const useUserBadge = (props: UserBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const userState = useAppSelector((state) => state.users);
    const [displayName, changeDisplayName] = useState<{ first_name?: string; last_name?: string } | undefined | string>({
        first_name: props.first_name,
        last_name: props.last_name
    });
    const [disabled, changeDisabled] = useState<boolean>(false);
    useEffect(() => {
        if (props.id != undefined) {
            const found = userState.unordered.find((u) => u.id == props.id);
            if (found) {
                changeDisplayName({ first_name: found.first_name, last_name: found.last_name });
            } else if (requested == false) {
                changeRequested(true);
                userRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        }
    }, [userState, props.id]);
    return { displayName, disabled };
};

export default useUserBadge;
