import { Rakinda, RakindaClient, fetchRakindas } from '@frontend/rakinda';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { RakindaSelectProps } from './rakinda-select.component';

interface ViewProps {
    options: { value: string; label: string }[];
    value?: { value: string; label: string };
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;
}

const pageSize = '100';
const useRakindaSelect = (props: RakindaSelectProps): ViewProps => {
    const dispatch = useAppDispatch();
    const timer = useRef<NodeJS.Timeout | null>(null);
    const rakindaState = useAppSelector((state) => state.rakindas);
    const [count, setCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const value = useMemo(() => {
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options]);

    useEffect(() => {
        dispatch(fetchRakindas({ index: index.toString(), size: pageSize }));
    }, [index]);

    useEffect(() => {
        if (rakindaState.rakindas) {
            setCount(rakindaState.rakindas.count);
            changeOptions(mapResultSetToOptions(rakindaState.rakindas.results));
        }
    }, [rakindaState.rakindas]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            RakindaClient.fetchRakindas({ search: inputValue, index: '0', size: pageSize }).then((result) => {
                callback(mapResultSetToOptions(result.results));
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    return {
        options,
        value,
        onScrollToBottom,
        search
    };
};

function mapResultSetToOptions(rakindas: Rakinda[]): { value: any; label: string }[] {
    return rakindas.map((r) => ({ value: r.id, label: r.name || r.mac_address }));
}

export default useRakindaSelect;
