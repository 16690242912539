import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateTerminal, SearchTerminalQueryParams, Terminal, TerminalListResponse, UpdateTerminal } from '../terminal';

const endpoint = '/terminal-service/api/v1';
export class TerminalClient extends APIClient {
    public static async fetchTerminals(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<TerminalListResponse> {
        return await this.apiPaginated<TerminalListResponse, DefaultQueryParams>(`${endpoint}/terminals`, queryParams);
    }

    public static async postTerminal(terminal: CreateTerminal): Promise<Terminal> {
        const response = await this.post(`${endpoint}/terminals`, terminal);
        return await this.handleResponse<Terminal>(response);
    }

    public static async searchTerminal(queryParams?: ApiQueryParams<DefaultQueryParams | SearchTerminalQueryParams>): Promise<TerminalListResponse> {
        return await this.apiPaginated<TerminalListResponse, DefaultQueryParams | SearchTerminalQueryParams>(`${endpoint}/terminals-search`, queryParams);
    }

    public static async fetchTerminal(terminalId: string): Promise<Terminal> {
        const response = await this.fetch(`${endpoint}/terminals/${terminalId}`);
        return await this.handleResponse<Terminal>(response);
    }

    public static async patchTerminal(terminal: UpdateTerminal, terminalId: string): Promise<Terminal> {
        const response = await this.patch(`${endpoint}/terminals/${terminalId}`, terminal);
        return await this.handleResponse<Terminal>(response);
    }

    public static async deleteTerminal(terminalId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/terminals/${terminalId}`);
        return await this.handleResponse(response);
    }

    public static async resolveTerminals(terminalIds: string[]): Promise<TerminalListResponse> {
        const response = await this.post(`${endpoint}/terminals-resolve`, { ids: terminalIds });
        return await this.handleResponse<TerminalListResponse>(response);
    }
}
