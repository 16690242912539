import { NameBadge } from '@frontend/elements';

import { DetailModalType } from '../../modals/detail-modal-container/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useUserBadge from './user-badge.controller';

export interface UserBadgeProps {
    id?: string;
    first_name?: string;
    last_name?: string;
}

const UserBadge = (props: UserBadgeProps) => {
    const viewProps = useUserBadge(props);

    return (
        <BadgeTemplate
            disabled={viewProps.disabled}
            type={DetailModalType.USER}
            objectId={props.id}>
            {!viewProps.displayName ? (
                <span className='placeholder'>
                    <span className='placeholder-glow'>Loading</span>
                </span>
            ) : typeof viewProps.displayName === 'string' ? (
                viewProps.displayName
            ) : (
                <NameBadge
                    firstName={viewProps.displayName.first_name}
                    lastName={viewProps.displayName.last_name}
                />
            )}
        </BadgeTemplate>
    );
};

export default UserBadge;
