import { Logger } from '@frontend/Logger';
import { AuthenticationManager } from '@frontend/authentication-v2';

export enum WebsocketState {
    NEW,
    INIT,
    CONNECTED,
    ERROR
}

export abstract class PubSubConnection {
    #blocked: boolean;
    #service: string;
    #websocket: WebSocket | undefined;
    #websocketState: WebsocketState = WebsocketState.NEW;

    constructor(service: string) {
        this.#blocked = false;
        this.#service = service;
        this.initiateWebsocket();
    }

    get service() {
        return this.#service;
    }
    get websocket() {
        return this.#websocket;
    }
    get websocketState() {
        return this.#websocketState;
    }
    set websocketState(state: WebsocketState) {
        this.#websocketState = state;
    }

    protected abstract onMessageEvent(event: MessageEvent<string>): void;

    public send = (message: string): boolean => {
        if (this.#websocket == undefined || this.#websocketState === WebsocketState.NEW) {
            Logger.error('No websocket connection available. Skip sending message.', {}, message);
            return false;
        }
        if (this.#websocketState === WebsocketState.INIT) {
            Logger.error('Websocket connection available but not ready yet. Skip sending message.', {}, message);
            return false;
        }
        this.#websocket.send(message);
        return true;
    };

    public cleanup() {
        if (this.#websocket) {
            this.#websocket.close(1000);
            this.#websocket.onopen = null;
            this.#websocket.onmessage = null;
            this.#websocket.onclose = null;
            this.#websocket.onerror = null;
        }
    }

    public async destroy() {
        await new Promise((resolve) =>
            setInterval(() => {
                if (!this.#blocked) resolve(null);
            }, 500)
        );
        this.cleanup();
        this.#websocket = undefined;
    }

    protected initiateWebsocket() {
        this.#blocked = true;
        let wssUrl = process.env['NX_WS_URL'] + '/' + this.#service + '-service/api/v1/subscribe';
        if (process.env['NX_BUILD_ENV'] === 'azure' || process.env['NX_BUILD_ENV'] === 'azure.production') {
            wssUrl = 'wss://' + this.#service + '-service' + process.env['NX_API_URL'] + '/' + this.#service + '-service/api/v1/subscribe';
        }

        AuthenticationManager.getInstance()
            .getTokenWhenReady()
            .then((token) => {
                this.#websocket = new WebSocket(`${wssUrl}?jwt_token=${token.jwt_token}`);
                this.#websocket.onopen = this.onOpen;
                this.#websocket.onmessage = (event: MessageEvent<string>) => this.onMessage(event);
                this.#websocket.onclose = (event: CloseEvent) => this.onClose(event);
                this.#websocket.onerror = (event: Event) => this.onError(event);
            })
            .catch(() => {
                Logger.warn('No valid token information found. Thus unable to connect websocket.');
            })
            .finally(() => {
                this.#blocked = false;
            });
    }

    protected onOpen(event: Event): void {
        if (this.websocketState === WebsocketState.INIT) {
            Logger.log('PubSub websocket connection succeeded.', {}, this.websocketState);
            this.websocketState = WebsocketState.CONNECTED;
            dispatchEvent(new CustomEvent<Event>('PubSub:onOpen', event));
        }
    }

    protected onMessage(event: MessageEvent<string>): void {
        Logger.log('Received PubSub websocket message.', {}, event.data);
        dispatchEvent(new CustomEvent<MessageEvent<string>>('PubSub:onMessage', { detail: event }));
        this.onMessageEvent(event);
    }

    protected onClose(event: CloseEvent): void {
        Logger.warn('PubSub websocket connection closed.', {}, event.reason);
        this.cleanup();
        this.#websocket = undefined;
        this.initiateWebsocket();
        this.#websocketState = WebsocketState.NEW;
        dispatchEvent(new CustomEvent<CloseEvent>('PubSub:onClose', event));
    }

    protected onError(event: Event): void {
        Logger.error('PubSub websocket connection error.', {}, event);
        this.cleanup();
        this.websocketState = WebsocketState.ERROR;
        setTimeout(() => {
            this.websocketState = WebsocketState.NEW;
            this.#websocket = undefined;
            this.initiateWebsocket();
        }, 2000);

        dispatchEvent(new CustomEvent<Event>('PubSub:onError', event));
    }
}
