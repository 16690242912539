import { Logger } from '@frontend/Logger';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { addWorkflow, removeWorkflow, updateWorkflow } from '../redux/workflow-slice';
import { Workflow } from '../workflow';

enum MessageTypes {
    UPDATED = 'workflow_updated',
    DELETED = 'workflow_deleted',
    CREATED = 'workflow_created'
}

export class WorkflowEventHandler {
    #dispatch: ThunkDispatch<any, any, AnyAction>;

    constructor(dispatch: ThunkDispatch<any, any, AnyAction>) {
        this.#dispatch = dispatch;
    }
    public onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: Workflow; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: Workflow; changes?: any }) {
        Logger.log('Updating workflow in store', {}, payload.data);
        this.#dispatch(updateWorkflow(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: Workflow }) {
        Logger.log('Removing workflow from store', {}, payload.data);
        this.#dispatch(removeWorkflow(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: Workflow; changes?: any }) {
        Logger.log('Adding workflow to store', {}, payload.data);
        this.#dispatch(addWorkflow(payload.data));
    }
}
