import { Spot, SpotState, spotRequest } from '@frontend/spot';
import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SpotBadgeProps } from './spot-badge.component';

interface ViewProps {
    onClick: () => void;
    displayName?: string;
    disabled: boolean;
}

const useSpotBadge = (props: SpotBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const navigate = useNavigate();
    const spotState: SpotState = useSelector((state: any) => state.spots);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (displayName == undefined && props.id != undefined) {
            const found = spotState.unordered.find((c: Spot) => c.id.toString() == props.id);
            if (found) {
                changeDisplayName(found.name);
            } else if (requested == false) {
                changeRequested(true);
                spotRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        }
    }, [spotState]);

    const onClick = () => {
        navigate(`/spot/${props.id}/`);
    };
    return { onClick, displayName, disabled };
};

export default useSpotBadge;
