import { ClassType } from '@frontend/common';
import { DefaultModal, DefaultModalProps, ModalFooter, ModalHeader, ModalType } from '@frontend/elements';
import { Rakinda } from '@frontend/rakinda';
import { FaEye } from 'react-icons/fa';

import RakindaDetail from '../rakinda-detail.component';
import { useRakindaDetailModal } from './rakinda-detail-modal.controller';

const ID = 'rakinda-detail-modal';

export interface RakindaDetailModalProps extends DefaultModalProps {
    rakinda: Rakinda;
}

const RakindaDetailModal = (props: RakindaDetailModalProps) => {
    const viewProps = useRakindaDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                handleClose={props.handleClose}
                id={ID + '-header'}
                title={props.rakinda.id}
            />
            <div className='modal-body'>
                <div className='d-flex flex-row w-100'>
                    <div className='d-flex flex-column w-100 h-100'>
                        <RakindaDetail rakinda={props.rakinda} />
                    </div>
                </div>
            </div>
            <ModalFooter
                buttons={[
                    {
                        text: 'View details',
                        icon: FaEye,
                        type: ClassType.PRIMARY,
                        onClick: () => viewProps.viewDetails()
                    }
                ]}
            />
        </DefaultModal>
    );
};

export default RakindaDetailModal;
