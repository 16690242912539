import { SelectInput } from '@frontend/basic-forms';
import React from 'react';

import useLanguageSelect from './language-select.controller';

const ID = 'language-select';

export interface LanguageSelectProps {
    value?: { value: string; label: string } | string;
    id?: string;
    required?: boolean;
    submitted: boolean;
    label?: string;
    onChange: (value: any) => void;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
}

export const LanguageSelect = (props: LanguageSelectProps) => {
    const viewProps = useLanguageSelect(props);

    return (
        <SelectInput
            id={props.id || ID}
            label={props.label || 'Language'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            onChange={props.onChange}
            value={viewProps.value}
            isDisabled={props.disabled}
            isClearable={props.isClearable}
        />
    );
};
