import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { WorkflowClient } from '../api/workflow-client';
import { Workflow, WorkflowListResponse } from '../workflow';
import { IncompleteWorkflowField } from '../workflow-field-template';

export interface WorkflowState {
    unordered: Workflow[];
    workflows: WorkflowListResponse | null;
    workflowsByAccount: { [accountId: string]: WorkflowListResponse } | null;
    incompleteFieldsByAccountAndWorkflow: { [accAndWorkId: string]: IncompleteWorkflowField[] } | null;
    status: SliceStatus;
}

const initialState: WorkflowState = {
    unordered: [],
    workflows: null,
    workflowsByAccount: null,
    incompleteFieldsByAccountAndWorkflow: null,
    status: SliceStatus.INIT
};

export const workflowSlice = createSlice({
    name: 'workflows',
    initialState,
    reducers: {
        seedWorkflows(state, action: PayloadAction<Workflow[]>) {
            state.unordered = [...state.unordered.filter((workflow) => action.payload.find((w) => w.id == workflow.id) == undefined), ...action.payload];
        },
        updateWorkflow(state, action: PayloadAction<Workflow>) {
            state.unordered = state.unordered.map((workflow) => (workflow.id == action.payload.id ? action.payload : workflow));
            if (state.workflows) {
                state.workflows.results = state.workflows.results.map((workflow) => (workflow.id == action.payload.id ? action.payload : workflow));
            }
            if (state.workflowsByAccount != null && state.workflowsByAccount[action.payload.account_id] != null) {
                state.workflowsByAccount[action.payload.account_id].results = state.workflowsByAccount[action.payload.account_id].results.map((workflow) =>
                    workflow.id == action.payload.id ? action.payload : workflow
                );
            }
        },
        addWorkflow(state, action: PayloadAction<Workflow>) {
            state.unordered.push(action.payload);
            if (state.workflows != null) {
                state.workflows.count++;
                state.workflows.results = [action.payload, ...state.workflows.results];
            }
            if (state.workflowsByAccount != null && state.workflowsByAccount[action.payload.account_id] != null) {
                state.workflowsByAccount[action.payload.account_id].results = [action.payload, ...state.workflowsByAccount[action.payload.account_id].results];
                state.workflowsByAccount[action.payload.account_id].count++;
            }
        },
        removeWorkflow(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((w) => w.id != action.payload);
            if (state.workflows != null) {
                state.workflows.results = state.workflows.results.filter((w) => w.id != action.payload);
            }
            if (state.workflowsByAccount != null) {
                Object.keys(state.workflowsByAccount).forEach((key) => {
                    state.workflowsByAccount![key].results = state.workflowsByAccount![key].results.filter((w) => w.id != action.payload);
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkflows.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchWorkflows.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.workflows == null) {
                    state.workflows = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.workflows.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.workflows.results.length !== action.payload.count) {
                        state.workflows.count = action.payload.count;
                        state.workflows.results = new Array(action.payload.count);
                    }
                    state.workflows.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchWorkflow.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchWorkflow.fulfilled, (state, action) => {
                const found = state.unordered.find((w) => w.id === action.meta.arg.workflowId);
                if (found) {
                    state.unordered[state.unordered.indexOf(found)] = action.payload;
                } else {
                    state.unordered = [...state.unordered, action.payload];
                }
            })
            .addCase(fetchAccountWorkflows.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountWorkflows.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.queryParams?.size) * toNumber(action.meta.arg.queryParams?.index);
                const accountId = action.meta.arg.accountId;
                if (state.workflowsByAccount == null) {
                    state.workflowsByAccount = { [accountId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.workflowsByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.workflowsByAccount) {
                    if (state.workflowsByAccount[accountId] == undefined) {
                        state.workflowsByAccount[accountId] = { ...action.payload, results: new Array(action.payload.count) };
                        state.workflowsByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (state.workflowsByAccount[accountId].results.length != action.payload.count) {
                            state.workflowsByAccount[accountId].count = action.payload.count;
                            state.workflowsByAccount[accountId].results = new Array(action.payload.count);
                        }
                        state.workflowsByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }
            })
            .addCase(fetchIncompleteAccountWorkflowFields.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchIncompleteAccountWorkflowFields.fulfilled, (state, action) => {
                const listId = action.meta.arg.accountId + ':' + action.meta.arg.workflowId;
                if (state.incompleteFieldsByAccountAndWorkflow == null) state.incompleteFieldsByAccountAndWorkflow = {};
                state.incompleteFieldsByAccountAndWorkflow[listId] = action.payload;
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchWorkflows = createAsyncThunk<WorkflowListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchWorkflows',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await WorkflowClient.fetchWorkflows(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchWorkflow = createAsyncThunk<Workflow, { accountId: string; workflowId: string }>(
    'fetchWorkflow',
    async (variables: { accountId: string; workflowId: string }, { rejectWithValue }) => {
        try {
            return await WorkflowClient.fetchAccountWorkflow(variables.accountId, variables.workflowId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountWorkflows = createAsyncThunk<WorkflowListResponse, { accountId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }>(
    'fetchAccountWorkflows',
    async (variables: { accountId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await WorkflowClient.fetchAccountWorkflows(variables.accountId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchIncompleteAccountWorkflowFields = createAsyncThunk<IncompleteWorkflowField[], { accountId: string; workflowId: string }>(
    'fetchIncompleteAccountWorkflowFields',
    async (params: { accountId: string; workflowId: string }, { rejectWithValue }) => {
        try {
            return await WorkflowClient.fetchIncompleteWorkflowFields(params.accountId, params.workflowId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const { seedWorkflows, updateWorkflow, addWorkflow, removeWorkflow } = workflowSlice.actions;
