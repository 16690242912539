import { APIClient } from '@frontend/api-utils';

const endpoint = '/rakinda-service/api/v1';

export class RakindaAccessClient extends APIClient {
    public static async openRakinda(rakindaId: string): Promise<string> {
        const response = await this.post(`${endpoint}/rakindas/${rakindaId}/open`);
        return await this.handleResponse<string>(response);
    }
}
