import { DefaultModalProps, LoadingModal, ModalType } from '@frontend/elements';

import AccountDetailModal from '../account-detail-modal/account-detail-modal.component';
import useAccountDetailModalWrapper from './account-detail-modal-wrapper.controller';

export interface AccountDetailModalWrapperProps extends DefaultModalProps {
    id: string;
}

const AccountDetailModalWrapper = (props: AccountDetailModalWrapperProps): JSX.Element => {
    const viewProps = useAccountDetailModalWrapper(props);
    if (!viewProps.account) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <AccountDetailModal
            account={viewProps.account}
            customWidth={40}
            {...props}
        />
    );
};

export default AccountDetailModalWrapper;
