import { ClassType } from '@frontend/common';
import React from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { GrCircleAlert, GrStatusWarning } from 'react-icons/gr';
import { MdErrorOutline } from 'react-icons/md';
import { VscDebug } from 'react-icons/vsc';

import { Event, EventLevel, EventType } from './event';

export class EventUtils {
    public static buildMessage(event: Event) {
        return {
            message: event.data,
            level: this.getEventLevel(event.level),
            type: this.getEventType(event.type),
            timestamp: event.creation_timestamp,
            id: event.id
        };
    }

    private static getEventLevel(level: EventLevel): { severity: ClassType; icon: React.ReactNode } {
        switch (level) {
            case EventLevel.WARNING:
                return {
                    severity: ClassType.WARNING,
                    icon: (
                        <GrStatusWarning
                            size={20}
                            className='text-warning'
                        />
                    )
                };
            case EventLevel.INFO:
                return {
                    severity: ClassType.INFO,
                    icon: (
                        <BsInfoCircle
                            size={20}
                            className='text-info display-1'
                        />
                    )
                };
            case EventLevel.ERROR:
                return {
                    severity: ClassType.DANGER,
                    icon: (
                        <MdErrorOutline
                            size={20}
                            className='text-danger'
                        />
                    )
                };
            case EventLevel.DEBUG:
                return {
                    severity: ClassType.DEBUG,
                    icon: (
                        <VscDebug
                            size={20}
                            className='text-primary'
                        />
                    )
                };
            case EventLevel.ALERT:
                return {
                    severity: ClassType.ALERT,
                    icon: (
                        <GrCircleAlert
                            size={20}
                            className='text-secondary'
                        />
                    )
                };
        }
    }

    private static getEventType(type: EventType) {
        switch (type) {
            case EventType.EMAIL_SENT:
                return 'Email sent';
            case EventType.NEW_RAKINDA_REGISTERED:
                return 'New rakinda registered';
            case EventType.RAKINDA_HEARTBEAT_MISSED:
                return 'Rakinda heartbeat missed';
            case EventType.RAKINDA_HEARTBEAT_RECOVERED:
                return 'Rakinda heartbeat recovered';
            case EventType.RAKINDA_MASTER_PIN_USED:
                return 'Rakinda master pin used';
            case EventType.RAKINDA_ACCESS_DENIED:
                return 'Rakinda access denied';
            case EventType.RAKINDA_ACCESS_GRANTED:
                return 'Rakinda access granted';
            case EventType.TRANSACTION_STATE_CHANGED:
                return 'Transaction state changed';
            default:
                return type;
        }
    }
}
