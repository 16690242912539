import { Logger } from '@frontend/Logger';
import { AuthenticationManager } from '@frontend/authentication';
import { merge } from 'lodash';

import { ApiError, ApiNonFieldErrors, ApiQueryParams, ApiViewSet, DefaultViewSetActions, DetailOptions } from './models';

export function isApiError(error: unknown): error is ApiError {
    return error !== undefined && error !== null && (error as ApiError).json !== undefined;
}

export function isApiErrorNonFieldErrors(json: unknown): json is ApiNonFieldErrors {
    return json !== undefined && json !== null && (json as ApiNonFieldErrors).non_field_errors !== undefined;
}

export function api(endpoint: string): string {
    if (process.env['NX_BUILD_ENV'] === 'azure' || process.env['NX_BUILD_ENV'] === 'azure.production') {
        return 'https://' + endpoint.split('/')[1] + process.env['NX_API_URL'] + endpoint;
    }
    return process.env['NX_API_URL']! + endpoint;
}

let lastRequests: { url: string; init?: RequestInit; timestamp: number }[] = [];
const requestTimeout = 2 * 1000;
const maxRequests = 3;

export async function fetchApi(endpoint: string, init?: RequestInit, includeAccessToken = true): Promise<Response> {
    if (includeAccessToken) {
        const credentials = await AuthenticationManager.getInstance().getTokenWhenReady();
        init = merge(init, {
            headers: {
                authorization: 'bearer ' + credentials.accessToken
            }
        });
    }
    if (!endpoint.startsWith('http')) {
        endpoint = api(endpoint);
    }
    lastRequests = lastRequests.filter((request) => Date.now() - request.timestamp < requestTimeout);

    const currentRequest = { url: endpoint, init, timestamp: Date.now() };
    const matchingRequest = lastRequests.find(
        (request) => request.url === currentRequest.url && JSON.stringify(request.init) === JSON.stringify(currentRequest.init)
    );

    if (matchingRequest) {
        return Promise.reject(new Error('Duplicate request'));
    }

    lastRequests.push(currentRequest);
    if (lastRequests.length > maxRequests) {
        lastRequests.splice(0, lastRequests.length - maxRequests);
    }
    let response = await window.fetch(endpoint, init);
    if (response.status === 401) {
        Logger.error('Unauthorized refresching token en refreshing page.');
        AuthenticationManager.getInstance().refreshToken();
        const credentials = await AuthenticationManager.getInstance().getTokenWhenReady();
        init = merge(init, {
            headers: {
                authorization: 'bearer ' + credentials.accessToken
            }
        });
        response = await window.fetch(endpoint, init);
    }
    return response;
}

export function postApi(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
    const isFormData = data instanceof FormData;
    return fetchApi(
        endpoint,
        {
            method: 'POST',
            ...(!isFormData && { headers: { 'Content-Type': 'application/json' } }),
            body: isFormData ? data : JSON.stringify(data)
        },
        includeAccessToken
    );
}

export function putApi(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
    const isFormData = data instanceof FormData;
    return fetchApi(
        endpoint,
        {
            method: 'PUT',
            ...(!isFormData && { headers: { 'Content-Type': 'application/json' } }),
            body: isFormData ? data : JSON.stringify(data)
        },
        includeAccessToken
    );
}

export function patchApi(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
    return fetchApi(
        endpoint,
        {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data ? JSON.stringify(data) : ''
        },
        includeAccessToken
    );
}

export function deleteApi(endpoint: string, includeAccessToken = true): Promise<Response> {
    return fetchApi(
        endpoint,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        },
        includeAccessToken
    );
}

export function buildApiEndpoint<T extends string>(endpoint: string, queryParams?: ApiQueryParams<T> | null): string {
    if (!queryParams) {
        return endpoint;
    }

    // This bizarre construct is necessary because Tyepscript 4.2.3 does not seem to understand that queryParams has
    // string values but instead uses unknown. This looks like a bug in Typescript. If you are using a newer version of
    // Typescript, try removing this parameter and see if it validates.
    const queryParams2: { [key in string]?: string | string[] | undefined | null } = queryParams;
    const searchParams = Object.entries(queryParams2).reduce((result, queryParam) => {
        if (queryParam[1]) {
            if (typeof queryParam[1] === 'string') {
                result.append(queryParam[0], queryParam[1]);
            } else {
                for (const queryParamValue of queryParam[1]) {
                    result.append(queryParam[0], queryParamValue);
                }
            }
        }
        return result;
    }, new URLSearchParams());
    const queryString = searchParams.toString();
    if (queryString !== '') {
        return `${endpoint}?${queryString}`;
    }
    return endpoint;
}

export function queryParamsToCacheKeys<T extends string>(enumVariable: { [key in string]: T }, queryParams?: ApiQueryParams<T> | null): ApiQueryParams<T> {
    const result = Object.fromEntries(Object.values(enumVariable).map((value) => [value, undefined]));
    return {
        ...result,
        ...queryParams
    };
}

export function queryEnabled<T extends string>(queryParams?: ApiQueryParams<T> | null): boolean {
    if (!queryParams) {
        return true;
    }

    return Object.values(queryParams).every((queryParamValue) => {
        return queryParamValue !== undefined;
    });
}

export async function apiList<T, S extends string | number>(viewSet: ApiViewSet, queryParams?: ApiQueryParams<S> | null): Promise<T[]> {
    const baseEndpoint = getEndpoint(viewSet, DefaultViewSetActions.LIST);
    const endpoint = buildApiEndpoint(baseEndpoint, queryParams);
    const response = await fetchApi(endpoint, undefined);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError(`Error fetching list of ${viewSet.baseName}`);
        }
        throw new ApiError(`Error fetching list of ${viewSet.baseName}`, json);
    }
    return await response.json();
}

export async function apiDetail<T>(viewSet: ApiViewSet, options: DetailOptions, init?: RequestInit): Promise<T> {
    let endpointUrl = options.url;
    if (!endpointUrl) {
        if (!options.id) {
            Logger.log('Unexpected empty url and id', {}, viewSet, options);
        }
        endpointUrl = getEndpoint(viewSet, DefaultViewSetActions.DETAIL, options.id);
    }
    const response = await fetchApi(endpointUrl, init);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError(`Error fetching detail of ${viewSet.baseName}`);
        }
        throw new ApiError(`Error fetching detail of ${viewSet.baseName}`, json);
    }
    return await response.json();
}

export async function apiPagination<T, S extends string | number>(viewSet: ApiViewSet, queryParams?: ApiQueryParams<S> | null, url?: string): Promise<T> {
    let endpoint;
    if (url) {
        endpoint = buildApiEndpoint(url, queryParams);
    } else {
        const baseEndpoint = getEndpoint(viewSet, DefaultViewSetActions.LIST);
        endpoint = buildApiEndpoint(baseEndpoint, queryParams);
    }

    const response = await fetchApi(endpoint, undefined);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError(`Error fetching  ${viewSet.baseName}`);
        }
        throw new ApiError(`Error fetching  ${viewSet.baseName}`, json);
    }
    return await response.json();
}

function getEndpoint(viewSet: ApiViewSet, action: DefaultViewSetActions, id?: string | number | null): string {
    const endpoint = viewSet.endpoints ? viewSet.endpoints[action] : undefined;
    if (endpoint) {
        return endpoint;
    }
    if (action === DefaultViewSetActions.LIST) {
        return `/${viewSet.baseName}/`;
    } else if (action === DefaultViewSetActions.DETAIL) {
        return `/${viewSet.baseName}/${id}/`;
    } else {
        Logger.log('Unexpected viewset action', undefined, action);
        return `/${viewSet.baseName}/`;
    }
}
