import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';

import { PermissionTemplatesDict, UserPermissions } from '../permission';
import { CreateRole, Role, RoleListResponse, UpdateRole } from '../role';

const endpoint = '/authorization-service/api/v1';
export class AuthorizationClient extends APIClient {
    public static async fetchRoles(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<RoleListResponse> {
        return await this.apiPaginated<RoleListResponse, DefaultQueryParams>(`${endpoint}/roles`, queryParams);
    }

    public static async fetchEntityRoles(type: EntityType, entityId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<RoleListResponse> {
        return await this.apiPaginated<RoleListResponse, DefaultQueryParams>(`${endpoint}/${type}s/${entityId}/roles`, queryParams);
    }

    public static async postEntityRole(role: CreateRole, type: EntityType, entityId: string): Promise<Role> {
        const response = await this.post(`${endpoint}/${type}s/${entityId}/roles`, role);
        return await this.handleResponse<Role>(response);
    }

    public static async fetchEntityRole(type: EntityType, entityId: string, roleId: string): Promise<Role> {
        const response = await this.fetch(`${endpoint}/${type}s/${entityId}/roles/${roleId}`);
        return await this.handleResponse<Role>(response);
    }

    public static async patchEntityRole(role: UpdateRole, type: EntityType, entityId: string, roleId: string): Promise<Role> {
        const response = await this.patch(`${endpoint}/${type}s/${entityId}/roles/${roleId}`, role);
        return await this.handleResponse<Role>(response);
    }

    public static async deleteEntityRole(type: EntityType, entityId: string, roleId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/${type}s/${entityId}/roles/${roleId}`);
        return await this.handleVoidResponse(response);
    }

    /**
     * Will fetch the persmissions linked to the token that is used to send the request.
     * (the users own permissions)
     */
    public static async fetchPermissions(): Promise<UserPermissions> {
        const response = await this.fetch(`${endpoint}/permissions`);
        return await this.handleResponse<UserPermissions>(response);
    }

    /**
     * Will fetch the permissions linked to the given entity_id.
     * @param type
     * @param entityId
     * @returns
     */
    public static async fetchEntityPermissions(type: EntityType, entityId: string): Promise<any> {
        const response = await this.fetch(`${endpoint}/${type}s/${entityId}/permissions`);
        return await this.handleResponse<any>(response);
    }

    public static async fetchRoleTemplates(): Promise<any> {
        const response = await this.fetch(`${endpoint}/role-templates`);
        return await this.handleResponse<any>(response);
    }

    public static async fetchPermissionTemplates(): Promise<PermissionTemplatesDict> {
        const response = await this.fetch(`${endpoint}/permission-templates`);
        return await this.handleResponse<PermissionTemplatesDict>(response);
    }
}
