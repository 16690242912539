import { DefaultModalProps, LoadingModal, ModalType } from '@frontend/elements';

import RakindaDetailModal from '../rakinda-detail-modal/rakinda-detail-modal.component';
import useRakindaDetailModalWrapper from './rakinda-detail-modal-wrapper.controller';

export interface RakindaDetailModalWrapperProps extends DefaultModalProps {
    id: string;
}

const RakindaDetailModalWrapper = (props: RakindaDetailModalWrapperProps): JSX.Element => {
    const viewProps = useRakindaDetailModalWrapper(props);

    if (!viewProps.rakinda) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <RakindaDetailModal
            rakinda={viewProps.rakinda}
            customWidth={40}
            {...props}
        />
    );
};

export default RakindaDetailModalWrapper;
