import { useMemo } from 'react';

import { ConstraintType } from '../../constraint-type';

interface ViewProps {
    options: { value: string; label: string }[];
}

const useConstraintTypeSelect = (): ViewProps => {
    const options = useMemo(() => {
        return Object.values(ConstraintType).map((type) => ({
            value: type,
            label: type
        }));
    }, []);
    return { options };
};

export default useConstraintTypeSelect;
