import { accountSlice } from '@frontend/account';
import { authorizationSlice } from '@frontend/authorization';
import { badgeSlice } from '@frontend/badge';
import { constraintSlice } from '@frontend/constraint';
import { contactSlice } from '@frontend/contact';
import { eventSlice } from '@frontend/event-service';
import { moduleSlice } from '@frontend/module';
import { notificationContentSlice, notificationTemplateSlice } from '@frontend/notification-service';
import { rakindaSlice } from '@frontend/rakinda';
import { slotSlice } from '@frontend/slot';
import { spotSlice } from '@frontend/spot';
import { terminalSlice } from '@frontend/terminal-service';
import { transactionSlice } from '@frontend/transaction';
import { userSlice } from '@frontend/user';
import { workflowButtonSlice, workflowSharedSlice, workflowSlice, workflowStepSlice } from '@frontend/workflow';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        accounts: accountSlice.reducer,
        workflows: workflowSlice.reducer,
        workflowSteps: workflowStepSlice.reducer,
        workflowButtons: workflowButtonSlice.reducer,
        workflowShared: workflowSharedSlice.reducer,
        contacts: contactSlice.reducer,
        users: userSlice.reducer,
        terminals: terminalSlice.reducer,
        authorization: authorizationSlice.reducer,
        spots: spotSlice.reducer,
        modules: moduleSlice.reducer,
        slots: slotSlice.reducer,
        constraints: constraintSlice.reducer,
        rakindas: rakindaSlice.reducer,
        transactions: transactionSlice.reducer,
        events: eventSlice.reducer,
        badges: badgeSlice.reducer,
        notificationTemplates: notificationTemplateSlice.reducer,
        notificationContents: notificationContentSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
