import React from 'react';

interface Props {
    fieldName: React.ReactNode;
    value?: React.ReactNode;
    className?: string;
}

export const ObjectField = (props: Props) => {
    if (props.value === undefined) return <></>;
    if (typeof props.value == 'string' && props.value == '') return <></>;
    return (
        <div
            className='d-flex justify-content-between'
            id={`${props.fieldName}`}>
            <strong className={props.className ? props.className : ''}>{props.fieldName}</strong>
            <p>{props.value}</p>
        </div>
    );
};
