import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { Contact, ContactListResponse, CreateContact, UpdateContact } from '../contact';

const endpoint = '/contact-service/api/v1';

export class ContactClient extends APIClient {
    public static async fetchContacts(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ContactListResponse> {
        return await this.apiPaginated<ContactListResponse, DefaultQueryParams>(`${endpoint}/contacts`, queryParams);
    }

    public static async postContact(contact: CreateContact): Promise<Contact> {
        const response = await this.post(`${endpoint}/contacts`, contact);
        return await this.handleResponse<Contact>(response);
    }

    public static async fetchContact(contactId: string): Promise<Contact> {
        const response = await this.fetch(`${endpoint}/contacts/${contactId}`);
        return await this.handleResponse<Contact>(response);
    }

    public static async patchContact(contact: UpdateContact, contactId: string): Promise<Contact> {
        const response = await this.patch(`${endpoint}/contacts/${contactId}`, contact);
        return await this.handleResponse<Contact>(response);
    }

    public static async deleteContact(contactId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/contacts/${contactId}`);
        return await this.handleResponse(response);
    }

    public static async resolveContacts(contactIds: string[]): Promise<ContactListResponse> {
        const response = await this.post(`${endpoint}/contacts-resolve`, { ids: contactIds });
        return await this.handleResponse<ContactListResponse>(response);
    }
}
