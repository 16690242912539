import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum ActionName {
    CHANGE_TRANSACTION_STATE = 'change_transaction_state',
    NOTIFY_SENDER = 'notify_sender',
    NOTIFY_RECEIVER = 'notify_receiver',
    RETIRE_PIN_CODE = 'retire_pin_code',
    CREATE_REMOVAL_CODE = 'create_removal_code',
    OPEN_GATE = 'open_gate'
}

export class WorkflowStepAction {
    static readonly CHANGE_TRANSACTION_STATE = new WorkflowStepAction(
        ActionName.CHANGE_TRANSACTION_STATE,
        (
            <FormattedMessage
                id='WorkflowStepAction.change_transaction_state'
                description='The displayed value for the WorkflowStepAction change_transaction_state'
                defaultMessage='Change transaction state'
            />
        )
    );
    static readonly NOTIFY_SENDER = new WorkflowStepAction(
        ActionName.NOTIFY_SENDER,
        (
            <FormattedMessage
                id='WorkflowStepAction.notify_sender'
                description='The displayed value for the WorkflowStepAction notify_sender'
                defaultMessage='Notify sender'
            />
        )
    );
    static readonly NOTIFY_RECEIVER = new WorkflowStepAction(
        ActionName.NOTIFY_RECEIVER,
        (
            <FormattedMessage
                id='WorkflowStepAction.notify_receiver'
                description='The displayed value for the WorkflowStepAction notify_receiver'
                defaultMessage='Notify receiver'
            />
        )
    );
    static readonly RETIRE_PIN_CODE = new WorkflowStepAction(
        ActionName.RETIRE_PIN_CODE,
        (
            <FormattedMessage
                id='WorkflowStepAction.retire_pin_code'
                description='The displayed value for the WorkflowStepAction retire_pin_code'
                defaultMessage='Retire pin code'
            />
        )
    );
    static readonly CREATE_REMOVAL_CODE = new WorkflowStepAction(
        ActionName.CREATE_REMOVAL_CODE,
        (
            <FormattedMessage
                id='WorkflowStepAction.create_removal_code'
                description='The displayed value for the WorkflowStepAction create_removal_code'
                defaultMessage='Create removal code'
            />
        )
    );
    static readonly OPEN_GATE = new WorkflowStepAction(
        ActionName.OPEN_GATE,
        (
            <FormattedMessage
                id='WorkflowStepAction.open_gate'
                description='The displayed value for the WorkflowStepAction open_gate'
                defaultMessage='Open gate'
            />
        )
    );

    static readonly ALL = [
        WorkflowStepAction.CHANGE_TRANSACTION_STATE,
        WorkflowStepAction.NOTIFY_SENDER,
        WorkflowStepAction.NOTIFY_RECEIVER,
        WorkflowStepAction.RETIRE_PIN_CODE,
        WorkflowStepAction.CREATE_REMOVAL_CODE,
        WorkflowStepAction.OPEN_GATE
    ];

    private constructor(public readonly value: ActionName, public readonly displayName: React.ReactNode) {}

    static getByValue(action: ActionName) {
        this.ALL.find((a) => a.value == action);
    }
}
