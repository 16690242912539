import { Transaction, TransactionClient, fetchTransactions } from '@frontend/transaction';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { TransactionSelectProps } from './transaction-select.component';

interface ViewProps {
    options: { value: string; label: string }[];
    value?: { value: string; label: string };
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;
}

const pageSize = '100';
const useTransactionSelect = (props: TransactionSelectProps): ViewProps => {
    const dispatch = useAppDispatch();
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const transactionState = useAppSelector((state) => state.transactions);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const value = useMemo(() => {
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options]);

    useEffect(() => {
        dispatch(fetchTransactions({ index: index.toString(), size: pageSize }));
    }, [index]);

    useEffect(() => {
        if (transactionState.transactions) {
            changeCount(transactionState.transactions.count);
            changeOptions(mapResultSetToOptions(transactionState.transactions.results));
        }
    }, [transactionState.transactions]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            TransactionClient.fetchTransactions({ search: inputValue, index: '0', size: pageSize }).then((result) => {
                callback(mapResultSetToOptions(result.results));
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };
    return {
        options,
        value,
        onScrollToBottom,
        search
    };
};

function mapResultSetToOptions(transactions: Transaction[]): { value: any; label: string }[] {
    return transactions.map((t) => ({ value: t.id, label: t.tracking_number }));
}

export default useTransactionSelect;
