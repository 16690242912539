import { Badge } from '@frontend/badge';
import { ObjectField } from '@frontend/elements';

import AccountBadge from '../../badges/account-badge/account-badge.component';
import UserBadge from '../../badges/user-badge/user-badge.component';

export interface BadgeDetailProps {
    badge: Badge;
}

const BadgeDetail = (props: BadgeDetailProps) => {
    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column w-100 h-100'>
                <ObjectField
                    fieldName='Number'
                    value={props.badge.number}
                />
                <ObjectField
                    fieldName='Account'
                    value={<AccountBadge id={props.badge.account_id} />}
                />
                <ObjectField
                    fieldName='User'
                    value={<UserBadge id={props.badge.user_id} />}
                />
            </div>
        </div>
    );
};

export default BadgeDetail;
