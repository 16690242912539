import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ConstraintClient } from '../api/constraint-client';
import { Constraint, ConstraintListResponse } from '../constraint';

interface ConstraintState {
    unordered: Constraint[];
    constraints: ConstraintListResponse | null;
    constraintsBySpot: { [spotId: string]: ConstraintListResponse } | null;
    status: SliceStatus;
}

const initialState: ConstraintState = {
    unordered: [],
    constraints: null,
    constraintsBySpot: null,
    status: SliceStatus.INIT
};

export const constraintSlice = createSlice({
    name: 'constraints',
    initialState,
    reducers: {
        seedConstraints(state, action: PayloadAction<Constraint[]>) {
            state.unordered = [...state.unordered.filter((constraint) => action.payload.find((c) => c.id == constraint.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConstraints.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchConstraints.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.constraints == null) {
                    state.constraints = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.constraints.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.constraints.results.length !== action.payload.count) {
                        state.constraints.count = action.payload.count;
                        state.constraints.results = new Array(action.payload.count);
                    }
                    state.constraints.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchConstraintsBySpot.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchConstraintsBySpot.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.queryParams.size) * toNumber(action.meta.arg.queryParams.index);
                if (state.constraintsBySpot == null) {
                    state.constraintsBySpot = { [action.meta.arg.spotId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.constraintsBySpot[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.constraintsBySpot[action.meta.arg.spotId] == undefined) {
                    state.constraintsBySpot[action.meta.arg.spotId] = { ...action.payload, results: new Array(action.payload.count) };
                    state.constraintsBySpot[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.constraintsBySpot[action.meta.arg.spotId].results.length !== action.payload.count) {
                        state.constraintsBySpot[action.meta.arg.spotId].count = action.payload.count;
                        state.constraintsBySpot[action.meta.arg.spotId].results = new Array(action.payload.count);
                    }
                    state.constraintsBySpot[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchConstraints = createAsyncThunk<ConstraintListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchConstraints',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await ConstraintClient.fetchConstraints(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchConstraintsBySpot = createAsyncThunk<ConstraintListResponse, { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }>(
    'fetchSpotConstraints',
    async (variables: { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await ConstraintClient.fetchSpotConstraints(variables.spotId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const { seedConstraints } = constraintSlice.actions;
