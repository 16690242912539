import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { Account, AccountListResponse } from '../account';
import { AccountClient } from '../api/account-client';

interface AccountState {
    unordered: Account[];
    accounts: AccountListResponse | null;
    status: SliceStatus;
}

const initialState: AccountState = {
    unordered: [],
    accounts: null,
    status: SliceStatus.INIT
};

export const accountSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        seedAccounts(state, action: PayloadAction<Account[]>) {
            state.unordered = [...state.unordered.filter((account) => action.payload.find((a) => a.id == account.id) == undefined), ...action.payload];
        },
        updateAccount(state, action: PayloadAction<Account>) {
            state.unordered = state.unordered.map((account) => (account.id == action.payload.id ? action.payload : account));
            if (state.accounts) {
                state.accounts.results = state.accounts.results.map((account) => (account.id == action.payload.id ? action.payload : account));
            }
        },
        addAccount(state, action: PayloadAction<Account>) {
            state.unordered.push(action.payload);
            if (state.accounts) {
                state.accounts.count++;
                state.accounts.results = [action.payload, ...state.accounts.results];
            }
        },
        removeAccount(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((account) => account.id != action.payload);
            if (state.accounts) {
                state.accounts.count--;
                state.accounts.results = state.accounts.results.filter((account) => account.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccounts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccounts.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.accounts == null) {
                    state.accounts = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.accounts.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.accounts.results.length !== action.payload.count) {
                        state.accounts.count = action.payload.count;
                        state.accounts.results = new Array(action.payload.count);
                    }
                    state.accounts.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((account) => action.payload.results.find((a) => a.id == account.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAccount.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccount.fulfilled, (state, action) => {
                if (state.accounts) {
                    const found = state.accounts.results.find((a) => a.id == action.meta.arg);
                    if (found) {
                        state.accounts.results.splice(state.accounts.results.indexOf(found), 1, action.payload);
                    } else {
                        state.accounts.results.push(action.payload);
                    }
                }
                state.status = SliceStatus.IDLE;
                state.unordered = [...state.unordered.filter((acc) => action.payload.id !== acc.id), action.payload];
            })
            .addCase(deleteAccount.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteAccount.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.accounts == null) return;
                const found = state.accounts.results.find((a) => a.id == action.meta.arg);
                if (found == undefined) return;
                state.accounts.results.splice(state.accounts.results.indexOf(found), 1);
            });
    }
});

export const fetchAccounts = createAsyncThunk<AccountListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchAccounts',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await AccountClient.fetchAccounts(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccount = createAsyncThunk<Account, string>('fetchAccount', async (accountId: string, { rejectWithValue }) => {
    try {
        return await AccountClient.fetchAccount(accountId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteAccount = createAsyncThunk<void, string>('deleteAccount', async (accountId: string, { rejectWithValue }) => {
    try {
        return await AccountClient.deleteAccount(accountId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const { seedAccounts, updateAccount, addAccount, removeAccount } = accountSlice.actions;
