import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateSpot, SearchSpotsQueryParams, Spot, SpotListResponse, SpotsQueryParams, UpdateSpot } from '../spot';

const endpoint = '/spot-service/api/v1';
export class SpotClient extends APIClient {
    static async fetchSpots(queryParams: ApiQueryParams<SpotsQueryParams | DefaultQueryParams>): Promise<SpotListResponse> {
        return this.apiPaginated<SpotListResponse, DefaultQueryParams>(`${endpoint}/spots`, queryParams);
    }

    static async fetchSpot(spotId: string): Promise<Spot> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}`);
        return await this.handleResponse<Spot>(response);
    }

    static async postSpot(spot: CreateSpot): Promise<Spot> {
        const response = await this.post(`${endpoint}/spots`, spot);
        return await this.handleResponse<Spot>(response);
    }

    static async patchSpot(spot: UpdateSpot, spotId: string): Promise<Spot> {
        const response = await this.patch(`${endpoint}/spots/${spotId}`, spot);
        return await this.handleResponse<Spot>(response);
    }

    static async searchSpots(queryParams: ApiQueryParams<DefaultQueryParams | SearchSpotsQueryParams>): Promise<SpotListResponse> {
        return await this.apiPaginated(`${endpoint}/spots-search`, queryParams);
    }

    static async resolveSpots(spotIds: string[]): Promise<SpotListResponse> {
        const response = await this.post(`${endpoint}/spots-resolve`, { ids: spotIds });
        return await this.handleResponse<SpotListResponse>(response);
    }

    static async deleteSpot(spotId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/spots/${spotId}`);
        return await this.handleVoidResponse(response);
    }
}
