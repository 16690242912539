import { useEffect, useRef } from 'react';

import { DefaultModalProps } from './default-modal.component';

interface ViewProps {
    modalType: string;
    width: any;
    modalRef: React.MutableRefObject<null>;
}

const useDefaultModal = (props: DefaultModalProps): ViewProps => {
    const modalRef = useRef(null);
    const modalType = props.type ? 'modal-' + props.type.valueOf() : 'modal-prompt';
    const width = props.customWidth !== undefined ? { width: props.customWidth + '%' } : {};

    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (modalRef?.current && !(modalRef.current as any).contains(event.target)) {
                props.show && props.handleClose && props.handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [props.handleClose, modalRef]);

    return {
        modalRef,
        modalType,
        width
    };
};

export default useDefaultModal;
