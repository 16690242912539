import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateTransaction, SearchTransactionQueryParams, Transaction, TransactionListResponse, UpdateTransaction } from '../transaction';

const endpoint = '/transaction-service/api/v1';
export class TransactionClient extends APIClient {
    public static async fetchTransactions(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<TransactionListResponse> {
        return await this.apiPaginated<TransactionListResponse, DefaultQueryParams>(`${endpoint}/transactions`, queryParams);
    }

    public static async searchTransactions(queryParams?: ApiQueryParams<DefaultQueryParams | SearchTransactionQueryParams>): Promise<TransactionListResponse> {
        return await this.apiPaginated<TransactionListResponse, DefaultQueryParams | SearchTransactionQueryParams>(
            `${endpoint}/transactions-search`,
            queryParams
        );
    }

    public static async resolveTransactions(transactionIds: string[]): Promise<TransactionListResponse> {
        const response = await this.post(`${endpoint}/transactions-resolve`, { ids: transactionIds });
        return await this.handleResponse<TransactionListResponse>(response);
    }

    public static async fetchSpotTransactions(spotId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<TransactionListResponse> {
        return await this.apiPaginated<TransactionListResponse, DefaultQueryParams>(`${endpoint}/spots/${spotId}/transactions`, queryParams);
    }

    public static async postSpotTransaction(createTransaction: CreateTransaction, spotId: string): Promise<Transaction> {
        const response = await this.post(`${endpoint}/spots/${spotId}/transactions`, createTransaction);
        return await this.handleResponse<Transaction>(response);
    }

    public static async fetchSpotTransaction(spotId: string, transactionId: string): Promise<Transaction> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/transactions/${transactionId}`);
        return await this.handleResponse<Transaction>(response);
    }

    public static async patchSpotTransaction(updateTransaction: UpdateTransaction, spotId: string, transactionId: string): Promise<Transaction> {
        const response = await this.patch(`${endpoint}/spots/${spotId}/transactions/${transactionId}`, updateTransaction);
        return await this.handleResponse<Transaction>(response);
    }

    public static async deleteSpotTransaction(spotId: string, transactionId: string): Promise<void> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/transactions/${transactionId}`);
        return await this.handleVoidResponse(response);
    }
}
