import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CopyWorkflow, CreateWorkflow, SearchWorkflowQueryParams, Workflow, WorkflowListResponse } from '../workflow';
import { IncompleteWorkflowField } from '../workflow-field-template';

const endpoint = '/workflow-service/api/v1';
export class WorkflowClient extends APIClient {
    public static async fetchWorkflows(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<WorkflowListResponse> {
        return await this.apiPaginated<WorkflowListResponse, DefaultQueryParams>(`${endpoint}/workflows`, queryParams);
    }

    public static async resolveWorkflows(workflowIds: string[]): Promise<WorkflowListResponse> {
        const response = await this.post(`${endpoint}/workflows-resolve`, { ids: workflowIds });
        return await this.handleResponse<WorkflowListResponse>(response);
    }

    public static async searchWorkflows(queryParams?: ApiQueryParams<DefaultQueryParams | SearchWorkflowQueryParams>): Promise<WorkflowListResponse> {
        return await this.apiPaginated<WorkflowListResponse, DefaultQueryParams | SearchWorkflowQueryParams>(`${endpoint}/workflows-search`, queryParams);
    }

    public static async fetchAccountWorkflows(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<WorkflowListResponse> {
        return await this.apiPaginated<WorkflowListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/workflows`, queryParams);
    }

    public static async postAccountWorkflow(workflow: CreateWorkflow, accountId: string): Promise<Workflow> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/workflows`, workflow);
        return await this.handleResponse<Workflow>(response);
    }

    public static async fetchAccountWorkflow(accountId: string, workflowId: string): Promise<Workflow> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}`);
        return await this.handleResponse<Workflow>(response);
    }

    public static async patchAccountWorkflow(workflow: Workflow, accountId: string, workflowId: string): Promise<Workflow> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}`, workflow);
        return await this.handleResponse<Workflow>(response);
    }

    public static async deleteAccountWorkflow(accountId: string, workflowId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/workflows/${workflowId}`);
        return await this.handleResponse(response);
    }

    public static async fetchIncompleteWorkflowFields(accountId: string, workflowId: string): Promise<IncompleteWorkflowField[]> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/incomplete-fields`);
        return await this.handleResponse<IncompleteWorkflowField[]>(response);
    }

    public static async copyAccountWorkflow(accountId: string, workflowId: string, body: CopyWorkflow): Promise<Workflow> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/copy`, body);
        return await this.handleResponse<Workflow>(response);
    }
}
