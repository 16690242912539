import { useMemo } from 'react';

import { WorkflowStepAction } from '../../workflow-step-actions';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
}

const useWorkflowStepActionSelect = (): ViewProps => {
    const options = useMemo(() => {
        return WorkflowStepAction.ALL.map((action) => {
            return {
                value: action.value,
                label: action.displayName
            };
        });
    }, []);

    return { options };
};

export default useWorkflowStepActionSelect;
