import { useAccounts } from '@frontend/account';
import { seedBadges, useBadges } from '@frontend/badge';
import { useScripts } from '@frontend/common';
import { seedConstraints, useConstraints } from '@frontend/constraint';
import { seedContacts, useContacts } from '@frontend/contact';
import { Spinner } from '@frontend/elements';
import { seedModules, useModules } from '@frontend/module';
import { seedRakindas, useRakindas } from '@frontend/rakinda';
import { seedSlots, useSlots } from '@frontend/slot';
import { seedSpots, useSpots } from '@frontend/spot';
import { seedTerminals, useTerminals } from '@frontend/terminal-service';
import { seedTransactions, useTransactions } from '@frontend/transaction';
import { seedUsers, useUsers } from '@frontend/user';
import { useWorkflows } from '@frontend/workflow';
import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import useLocale from '../lang/useLocale';
import Authentication from './authentication/authentication.component';
import RolesModalContainer from './authorization/roles-modal-container/roles-modal-container.component';
import { DetailModalContainer } from './modals/detail-modal-container/detail-modal-container/detail-modal-container.component';
import Navbar from './navigation/navbar/navbar.component';
import { useAppDispatch } from './redux/store';

const AccountModule = React.lazy(() => import('./accounts/account-list/account-list.component'));
const WorkflowModule = React.lazy(() => import('./workflows/workflow-list/workflow-list.component'));
const WorkflowDetialModule = React.lazy(() => import('./workflows/workflow-detail/workflow-detail.component'));
const TransactionModule = React.lazy(() => import('./transactions/transaction-list/transaction-list.component'));
const ContactModule = React.lazy(() => import('./contacts/contact-list/contact-list.component'));
const UserModule = React.lazy(() => import('./users/users-list/user-list.component'));
const TerminalModule = React.lazy(() => import('./terminals/terminal-list/terminal-list.component'));
const SpotModule = React.lazy(() => import('./spots/spot-list/spot-list.component'));
const RakindaModule = React.lazy(() => import('./rakindas/rakinda-list/rakinda-list.component'));
const SpotDetailModule = React.lazy(() => import('./spots/spot-detail/spot-detail.component'));
const SlotModule = React.lazy(() => import('./slots/slot-list/slot-list.component'));
const BadgeModule = React.lazy(() => import('./account-badges/badge-list/badge-list.component'));
const NotificationTemplateModule = React.lazy(
    () => import('./notifications/notification-templates/notification-template-list/notification-template-list.component')
);
const NotificationTemplateDetailModule = React.lazy(
    () => import('./notifications/notification-templates/notification-template-detail/notification-template-detail.component')
);
const NotificationContentDetailModule = React.lazy(
    () => import('./notifications/notification-content/notification-content-detail/notification-content-detail.component')
);
const AccountDetailModule = React.lazy(() => import('./accounts/account-detail/account-detail-page/account-detail-page.component'));
const ContactDetailModule = React.lazy(() => import('./contacts/contact-detail/contact-detail-page/contact-detail-page.component'));
const TerminalDetailModule = React.lazy(() => import('./terminals/terminal-detail/terminal-detail-page/terminal-detail-page.component'));
const UserDetailModule = React.lazy(() => import('./users/user-detail/user-detail-page/user-detail-page.component'));
const RakindaDetailModule = React.lazy(() => import('./rakindas/rakinda-detail/rakinda-detail-page/rakinda-detail-page.component'));
const EventEntitiesModule = React.lazy(() => import('./events/event-entities-list/event-entities-list.component'));
const BadgeDetailModule = React.lazy(() => import('./account-badges/badge-detail/badge-detail-page/badge-detail-page.component'));
const SlotDetailModule = React.lazy(() => import('./slots/slot-detail/slot-detail-page/slot-detail-page.component'));
const TransactionDetailModule = React.lazy(() => import('./transactions/transaction-detail-page/transaction-detail-page.component'));

export function App() {
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');
    const dispatch = useAppDispatch();
    const { messages, locale /*, changeLocale*/ } = useLocale();

    useAccounts({ dispatch, enablePubSub: true });
    useWorkflows({ dispatch, enablePubSub: true });

    useContacts({
        seedStore: (cts) => {
            dispatch(seedContacts(cts));
        }
    });

    useUsers({
        seedStore: (users) => {
            dispatch(seedUsers(users));
        }
    });

    useSpots({
        seedStore: (spt) => {
            dispatch(seedSpots(spt));
        }
    });

    useModules({
        seedStore: (mod) => {
            dispatch(seedModules(mod));
        }
    });

    useSlots({
        seedStore: (slt) => {
            dispatch(seedSlots(slt));
        }
    });

    useConstraints({
        seedStore: (cts) => {
            dispatch(seedConstraints(cts));
        }
    });

    useRakindas({
        seedStore: (rkd) => {
            dispatch(seedRakindas(rkd));
        }
    });

    useTerminals({
        seedStore: (trm) => {
            dispatch(seedTerminals(trm));
        }
    });

    useBadges({
        seedStore: (bdg) => {
            dispatch(seedBadges(bdg));
        }
    });

    useTransactions({
        seedStore: (trx) => {
            dispatch(seedTransactions(trx));
        }
    });

    return (
        <IntlProvider
            messages={messages}
            defaultLocale={locale}
            locale='en'>
            <div className='g-sidenav-show bg-gray-100 g-sidenav-pinned'>
                <div className='min-height-300 bg-primary position-absolute w-100 sticky-top top-bar' />
                <Router>
                    <Authentication>
                        <Navbar />
                        <main className='d-flex main-content position-relative border-radius-lg'>
                            <div className='container-fluid ps-4'>
                                <Suspense fallback={<Spinner />}>
                                    <Routes>
                                        <Route path='/account'>
                                            <Route
                                                index
                                                element={<AccountModule />}
                                            />
                                            <Route
                                                path='/account/:accountId/workflow/:workflowId'
                                                element={<WorkflowDetialModule />}
                                            />
                                            <Route path=':accountId/notification-template/:templateId'>
                                                <Route
                                                    index
                                                    element={<NotificationTemplateDetailModule />}
                                                />
                                                <Route
                                                    path='content/:contentId'
                                                    element={<NotificationContentDetailModule />}
                                                />
                                            </Route>
                                            <Route
                                                path=':accountId/detail'
                                                element={<AccountDetailModule />}
                                            />
                                        </Route>
                                        <Route path='/workflow'>
                                            <Route
                                                index
                                                element={<WorkflowModule />}
                                            />
                                        </Route>
                                        <Route path='/transaction'>
                                            <Route
                                                index
                                                element={<TransactionModule />}
                                            />
                                            <Route
                                                path=':transactionId/detail'
                                                element={<TransactionDetailModule />}
                                            />
                                        </Route>

                                        <Route path='/contact'>
                                            <Route
                                                index
                                                element={<ContactModule />}
                                            />
                                            <Route
                                                path=':contactId/detail'
                                                element={<ContactDetailModule />}
                                            />
                                        </Route>
                                        <Route path='/terminal'>
                                            <Route
                                                index
                                                element={<TerminalModule />}
                                            />
                                            <Route
                                                path=':terminalId/detail'
                                                element={<TerminalDetailModule />}
                                            />
                                        </Route>
                                        <Route path='/user'>
                                            <Route
                                                index
                                                element={<UserModule />}
                                            />
                                            <Route
                                                path=':userId/detail'
                                                element={<UserDetailModule />}
                                            />
                                        </Route>
                                        <Route path='/spot'>
                                            <Route
                                                index
                                                element={<SpotModule />}
                                            />
                                            <Route
                                                path='/spot/:spotId'
                                                element={<SpotDetailModule />}
                                            />
                                        </Route>
                                        <Route path='/slot'>
                                            <Route
                                                index
                                                element={<SlotModule />}
                                            />
                                            <Route
                                                path=':slotId/detail'
                                                element={<SlotDetailModule />}
                                            />
                                        </Route>
                                        <Route path='/rakinda'>
                                            <Route
                                                index
                                                element={<RakindaModule />}
                                            />
                                            <Route
                                                path=':rakindaId/detail'
                                                element={<RakindaDetailModule />}
                                            />
                                        </Route>

                                        <Route path='/badge'>
                                            <Route
                                                index
                                                element={<BadgeModule />}
                                            />
                                            <Route
                                                path=':badgeId/detail'
                                                element={<BadgeDetailModule />}
                                            />
                                        </Route>
                                        <Route path='/notification-template'>
                                            <Route
                                                index
                                                element={<NotificationTemplateModule />}
                                            />
                                        </Route>
                                        <Route
                                            path='/event/:eventId/entities'
                                            element={<EventEntitiesModule />}
                                        />
                                        <Route
                                            path='/'
                                            element={<Navigate to='/account' />}
                                        />
                                        <Route
                                            path='/*'
                                            element={<Navigate to='/account' />}
                                        />
                                    </Routes>
                                    <DetailModalContainer />
                                    <RolesModalContainer />
                                </Suspense>
                            </div>
                        </main>
                    </Authentication>
                </Router>
                <ToastContainer />
            </div>
        </IntlProvider>
    );
}

export default App;
