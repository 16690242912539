import { useDetailPage } from '@frontend/common';
import { DateBadge, ObjectField, SpotBadge, TransactionStateBadge } from '@frontend/elements';
import { WorkflowBadge } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Transaction } from '@frontend/transaction';

import AccountBadge from '../../badges/account-badge/account-badge.component';

export interface TransactionDetailProps {
    transaction: Transaction;
}

export const TransactionDetail = (props: TransactionDetailProps) => {
    const viewProps = useDetailPage('transaction');
    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column h-100 w-100'>
                <ObjectField
                    fieldName='Tracking number'
                    value={props.transaction.tracking_number}
                />
                <ObjectField
                    fieldName='SPOT'
                    value={<SpotBadge id={props.transaction.spot_id} />}
                />
                {viewProps.isDetailPage && (
                    <>
                        <ObjectField
                            fieldName='Account'
                            value={<AccountBadge id={props.transaction.account_id} />}
                        />
                        <ObjectField
                            fieldName='Workflow'
                            value={<WorkflowBadge id={props.transaction.workflow_id} />}
                        />
                        <ObjectField
                            fieldName='State'
                            value={<TransactionStateBadge state={props.transaction.state} />}
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.transaction.creation_timestamp}
                                    displayTime
                                />
                            }
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.transaction.update_timestamp}
                                    displayTime
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
};
