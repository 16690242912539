import { EntityType } from '@frontend/common';
import React from 'react';

import { DefaultSelectProps } from '../basic-forms';
import { SelectInput } from '../select-input/select-input.component';

const ID = 'entity-type-select';

export const EntityTypeSelect = (props: DefaultSelectProps) => {
    return (
        <SelectInput
            {...props}
            id={props.id || ID}
            isClearable
            options={Object.values(EntityType).map((c) => ({ label: c, value: c }))}
            value={props.value}
            label={props.label || 'Entity'}
            submitted={props.submitted}
            required={props.required}
            onChange={(value) => props.onChange && props.onChange((value as { label: string; value: string }).value)}
        />
    );
};
