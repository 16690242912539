import { ClassType } from '@frontend/common';
import { DefaultModal, DefaultModalProps, ModalFooter, ModalHeader, ModalType } from '@frontend/elements';
import { Terminal } from '@frontend/terminal-service';
import { FaEye } from 'react-icons/fa';

import { TerminalDetail } from '../terminal-detail.component';
import { useTerminalDetailModal } from './terminal-detail-modal.controller';

const ID = 'terminal-detail-modal';

export interface TerminalDetailModalProps extends DefaultModalProps {
    terminal: Terminal;
}

const TerminalDetailModal = (props: TerminalDetailModalProps) => {
    const viewProps = useTerminalDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id={ID + '-header'}
                handleClose={props.handleClose}
                title={props.terminal.identifier}
            />
            <div className='modal-body'>
                <TerminalDetail terminal={props.terminal} />
            </div>
            <ModalFooter
                buttons={[
                    {
                        text: 'View details',
                        icon: FaEye,
                        type: ClassType.PRIMARY,
                        onClick: () => viewProps.viewDetails()
                    }
                ]}
            />
        </DefaultModal>
    );
};

export default TerminalDetailModal;
