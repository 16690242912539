import React from 'react';

import { BadgeTemplate, DetailModalType } from '../badge-template/badge-template.component';
import useEventEntityBadge from './event-entity-badge.controller';

export interface EventEntityBadgeProps {
    entityId: string;
    entityType: string;
}

export const EventEntityBadge = (props: EventEntityBadgeProps) => {
    const viewProps = useEventEntityBadge(props);

    if (viewProps.badge) {
        return viewProps.badge;
    }
    return (
        <BadgeTemplate
            disabled={viewProps.type === undefined}
            objectId={props.entityId}
            type={viewProps.type === undefined ? DetailModalType.UNKNOWN : viewProps.type}>
            {viewProps.type === undefined ? (
                <span className='placeholder'>
                    <span className='placeholder-glow'>Loading</span>
                </span>
            ) : (
                props.entityId
            )}
        </BadgeTemplate>
    );
};
