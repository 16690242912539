import { useLocation } from 'react-router-dom';

interface ViewProps {
    isDetailPage: boolean;
}

export const useDetailPage = (type: string): ViewProps => {
    const { pathname } = useLocation();

    return {
        isDetailPage: pathname.includes('detail') && pathname.includes(type)
    };
};
