import React from 'react';

import useBadgeTemplate from './badge-template.controller';

export interface BadgeTemplateProps {
    type: DetailModalType;
    objectId?: string | null;
    children: React.ReactNode;
    disabled?: boolean;
}

export const BadgeTemplate = (props: BadgeTemplateProps) => {
    const viewProps = useBadgeTemplate(props);

    return (
        <span
            id={'badge-' + props.objectId}
            className={`badge badge-secondary ${!props.disabled && 'cursor-pointer'} m-1`}
            onClick={() => viewProps.onClick()}>
            {props.children}
        </span>
    );
};

export enum DetailModalType {
    ACCOUNT = 'account',
    CONTACT = 'contact',
    USER = 'user',
    TERMINAL = 'terminal',
    RAKINDA = 'rakinda',
    SLOT = 'slot',
    BADGE = 'badge',
    TRANSACTION = 'transaction',
    UNKNOWN = 'unknown'
}
