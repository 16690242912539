import React from 'react';

import { DetailModalType } from '../badge-template/badge-template.component';
import { SpotBadge } from '../spot-badge/spot-badge.component';
import { WorkflowBadge } from '../workflow-badge/workflow-badge.component';
import { EventEntityBadgeProps } from './event-entity-badge.component';

interface ViewProps {
    type?: DetailModalType;
    badge?: React.ReactNode;
}

const useEventEntityBadge = (props: EventEntityBadgeProps): ViewProps => {
    switch (props.entityType) {
        case 'account':
            return { type: DetailModalType.ACCOUNT };
        case 'rakinda':
            return { type: DetailModalType.RAKINDA };
        case 'slot':
            return { type: DetailModalType.SLOT };
        case 'contact':
            return { type: DetailModalType.CONTACT };
        case 'user':
            return { type: DetailModalType.USER };
        case 'terminal':
            return { type: DetailModalType.TERMINAL };
        case 'badge':
            return { type: DetailModalType.BADGE };
        case 'spot':
            return { badge: <SpotBadge id={props.entityId} /> };
        case 'workflow':
            return { badge: <WorkflowBadge id={props.entityId} /> };
        case 'transaction':
            return { type: DetailModalType.TRANSACTION };
        default:
            return { type: undefined };
    }
};

export default useEventEntityBadge;
