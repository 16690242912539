import { DefaultModalProps, LoadingModal, ModalType } from '@frontend/elements';

import TerminalDetailModal from '../terminal-detail-modal/terminal-detail-modal.component';
import useTerminalDetailModalWrapper from './terminal-detail-modal-wrapper.controller';

export interface TerminalDetailModalWrapperProps extends DefaultModalProps {
    id: string;
}

const TerminalDetailModalWrapper = (props: TerminalDetailModalWrapperProps): JSX.Element => {
    const viewProps = useTerminalDetailModalWrapper(props);
    if (!viewProps.terminal) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <TerminalDetailModal
            terminal={viewProps.terminal}
            customWidth={40}
            {...props}
        />
    );
};

export default TerminalDetailModalWrapper;
