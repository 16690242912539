import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { WorkflowButtonClient } from '../api/workflow-button-client';
import { WorkflowButton, WorkflowButtonListResponse } from '../workflow-button';

interface WorkflowButtonState {
    unordered: WorkflowButton[];
    workflowButtonsByAccountAndWorkflow: { [accAndWorkId: string]: WorkflowButtonListResponse } | null;
    userWorkflowButtons: WorkflowButtonListResponse | null;
    status: SliceStatus;
}

const initialState: WorkflowButtonState = {
    unordered: [],
    workflowButtonsByAccountAndWorkflow: null,
    userWorkflowButtons: null,
    status: SliceStatus.INIT
};

export const workflowButtonSlice = createSlice({
    name: 'workflowButtons',
    initialState,
    reducers: {
        updateWorkflowButton: (state, action: PayloadAction<WorkflowButton>) => {
            state.unordered = state.unordered.map((workflowButton) => (workflowButton.id == action.payload.id ? action.payload : workflowButton));
            if (state.workflowButtonsByAccountAndWorkflow != null) {
                const listId = action.payload.account_id + ':' + action.payload.workflow_id;
                if (state.workflowButtonsByAccountAndWorkflow[listId] != null) {
                    state.workflowButtonsByAccountAndWorkflow[listId].results = state.workflowButtonsByAccountAndWorkflow[listId].results.map(
                        (workflowButton: WorkflowButton) => (workflowButton.id == action.payload.id ? action.payload : workflowButton)
                    );
                }
            }
            if (state.userWorkflowButtons != null) {
                state.userWorkflowButtons.results = state.userWorkflowButtons.results.map((workflowButton: WorkflowButton) =>
                    workflowButton.id == action.payload.id ? action.payload : workflowButton
                );
            }
        },
        addWorkflowButton(state, action: PayloadAction<WorkflowButton>) {
            state.unordered.push(action.payload);
            if (state.workflowButtonsByAccountAndWorkflow != null) {
                const listId = action.payload.account_id + ':' + action.payload.workflow_id;
                if (state.workflowButtonsByAccountAndWorkflow[listId] != null) {
                    state.workflowButtonsByAccountAndWorkflow[listId].count++;
                    state.workflowButtonsByAccountAndWorkflow[listId].results = [action.payload, ...state.workflowButtonsByAccountAndWorkflow[listId].results];
                }
            }
            if (state.userWorkflowButtons != null) {
                state.userWorkflowButtons.results = [action.payload, ...state.userWorkflowButtons.results];
                state.userWorkflowButtons.count++;
            }
        },
        removeWorkflowButton(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((w) => action.payload != w.id);
            if (state.workflowButtonsByAccountAndWorkflow != null) {
                Object.keys(state.workflowButtonsByAccountAndWorkflow).forEach((key) => {
                    state.workflowButtonsByAccountAndWorkflow![key].results = state.workflowButtonsByAccountAndWorkflow![key].results.filter(
                        (w) => w.id != action.payload
                    );
                });
            }
            if (state.userWorkflowButtons != null) {
                state.userWorkflowButtons.results = state.userWorkflowButtons.results.filter((w) => w.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountWorkflowButtons.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountWorkflowButtons.fulfilled, (state, action) => {
                if (action.meta.arg.queryParams.size == null || action.meta.arg.queryParams.index == null) return;
                const listId = action.meta.arg.accountId + ':' + action.meta.arg.workflowId;
                const startPos = toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1);
                if (state.workflowButtonsByAccountAndWorkflow == null) state.workflowButtonsByAccountAndWorkflow = {};
                if (state.workflowButtonsByAccountAndWorkflow[listId] == undefined) {
                    state.workflowButtonsByAccountAndWorkflow[listId] = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.workflowButtonsByAccountAndWorkflow[listId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.workflowButtonsByAccountAndWorkflow[listId].results.length !== action.payload.count) {
                        state.workflowButtonsByAccountAndWorkflow[listId].count = action.payload.count;
                        state.workflowButtonsByAccountAndWorkflow[listId].results = new Array(action.payload.count);
                    }
                    state.workflowButtonsByAccountAndWorkflow[listId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUserWorkflowButtons.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUserWorkflowButtons.fulfilled, (state, action) => {
                state.userWorkflowButtons = action.payload;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUserWorkflowButtons.rejected, (state) => {
                state.userWorkflowButtons = { count: 0, results: [] };
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchAccountWorkflowButtons = createAsyncThunk<
    WorkflowButtonListResponse,
    { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchAccountWorkflowButtons',
    async (params: { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await WorkflowButtonClient.fetchAccountWorkflowButtons(params.accountId, params.workflowId, params.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchUserWorkflowButtons = createAsyncThunk<WorkflowButtonListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchUserWorkflowButtons',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await WorkflowButtonClient.fetchUserWorkflowButtons(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const { updateWorkflowButton, addWorkflowButton, removeWorkflowButton } = workflowButtonSlice.actions;
