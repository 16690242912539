import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RakindaDetailModalProps } from './rakinda-detail-modal.component';

interface ViewProps {
    showUpdateModal: boolean;
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    showOpenRakindaModal: boolean;
    changeShowOpenRakindaModal: React.Dispatch<React.SetStateAction<boolean>>;
    viewDetails: () => void;
}

export const useRakindaDetailModal = (props: RakindaDetailModalProps): ViewProps => {
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [showOpenRakindaModal, changeShowOpenRakindaModal] = useState<boolean>(false);
    const navigate = useNavigate();

    function viewDetails() {
        navigate(`/rakinda/${props.rakinda?.id}/detail`);
    }

    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        showDeleteModal,
        showUpdateModal,
        changeShowOpenRakindaModal,
        showOpenRakindaModal,
        viewDetails
    };
};
