import React from 'react';

import useDefaultModal from './default-modal.controller';

const ID = 'default-modal';
export interface DefaultModalProps {
    id?: string;
    handleClose: () => void;
    show: boolean;
    children?: React.ReactNode;
    type?: ModalType;
    customWidth?: number;
}

export const DefaultModal = (props: DefaultModalProps) => {
    const viewProps = useDefaultModal(props);

    if (!props.show) {
        return <></>;
    }

    return (
        <div
            className='modal display-block'
            aria-modal='true'
            role='dialog'
            id={ID}>
            <div
                className={`${viewProps.modalType} ${props.show ? '' : 'hide'}`}
                style={viewProps.width}
                ref={viewProps.modalRef}>
                {props.children}
            </div>
        </div>
    );
};

export enum ModalType {
    PROMPT = 'prompt',
    PANE = 'pane'
}
