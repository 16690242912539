import { EventMessage } from '@frontend/event-service';
import { Column } from 'react-table';

const EventColumns: Column<EventMessage>[] = [
    {
        Header: 'type',
        accessor: 'type'
    },
    {
        Header: 'severity',
        accessor: (e) => e.level.severity
    },
    {
        Header: 'message',
        accessor: 'message'
    },
    {
        Header: 'timestamp',
        accessor: 'timestamp'
    },
    {
        Header: 'Actions'
    }
];

export default EventColumns;
