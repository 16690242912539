import {
    AuthorizationClient,
    CreateRole,
    PermissionTemplatesDict,
    fetchEntityRoles,
    fetchPermissionTemplates,
    fetchRoleTemplates
} from '@frontend/authorization';
import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { CreateRoleFormProps } from './create-role-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateRole;
    objectTypes: string[] | null;
    roleNames: string[] | null;
    formValid: FormValid;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateRole>>;
    isValid: boolean;
    onClear: () => void;
    permissions: { item: string; rights: string[] }[] | null;
}

interface FormValid {
    name: boolean;
    item: boolean;
    uid: boolean;
}
const useCreateRoleForm = (props: CreateRoleFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const authorizationState = useAppSelector((state) => state.authorization);
    const [formValues, changeFormValues] = useState<CreateRole>({ name: '', item: '', uid: '' });
    const [formValid, changeFormValid] = useState<FormValid>({ name: false, item: false, uid: false });
    const [permissionTemplates, changePermissionTemplates] = useState<PermissionTemplatesDict | null>(null);
    const objectTypes = useMemo(() => {
        if (authorizationState.roleTemplates == null) return null;
        return Object.keys(authorizationState.roleTemplates);
    }, [authorizationState.roleTemplates]);
    const roleNames = useMemo(() => {
        if (!formValid.item || formValues.item == null || authorizationState.roleTemplates == null || authorizationState.roleTemplates[formValues.item] == null)
            return null;
        return authorizationState.roleTemplates[formValues.item];
    }, [formValues.item, authorizationState.roleTemplates, formValid.item]);
    const permissions = useMemo(() => {
        if (!permissionTemplates || formValues.item == '' || formValues.name == '') return null;
        const itemPermissions = permissionTemplates[formValues.item.valueOf()];
        const rolePermissions = itemPermissions[formValues.name.valueOf()];
        return rolePermissions;
    }, [formValues.item, formValues.name]);

    useEffect(() => {
        if (authorizationState.roleTemplates == null) dispatch(fetchRoleTemplates());
        if (authorizationState.permissionTemplates == null) dispatch(fetchPermissionTemplates());
    }, []);

    useEffect(() => {
        if (authorizationState.permissionTemplates !== null) changePermissionTemplates(authorizationState.permissionTemplates);
    }, [authorizationState.permissionTemplates]);

    const onClear = (e?: any) => {
        if (e) e.preventDefault();
        changeFormValues({ name: '', item: '', uid: '' });
        changeFormValid({ name: false, item: false, uid: false });
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        if (authorizationState.current == null) return;
        AuthorizationClient.postEntityRole(formValues, authorizationState.current.type, authorizationState.current.id).then(() => {
            if (authorizationState.current) dispatch(fetchEntityRoles({ type: authorizationState.current.type, entityId: authorizationState.current.id }));
            onClear();
        });
    };

    return {
        submitted,
        formValues,
        objectTypes,
        roleNames,
        formValid,
        changeFormValid,
        onSubmit,
        changeFormValues,
        isValid: Object.values(formValid).find((v) => !v) == undefined && permissions != undefined,
        onClear,
        permissions
    };
};

export default useCreateRoleForm;
