import { DefaultModalProps, LoadingModal, ModalType } from '@frontend/elements';

import UserDetailModal from '../user-detail-modal/user-detail-modal.component';
import useUserDetailModalWrapper from './user-detail-modal-wrapper.controller';

export interface UserDetailModalWrapperProps extends DefaultModalProps {
    id: string;
}

const UserDetailModalWrapper = (props: UserDetailModalWrapperProps): JSX.Element => {
    const viewProps = useUserDetailModalWrapper(props);
    if (!viewProps.user) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <UserDetailModal
            user={viewProps.user}
            customWidth={40}
            {...props}
        />
    );
};

export default UserDetailModalWrapper;
