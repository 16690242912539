import { Account } from '@frontend/account';
import { AddressBadge, DateBadge, ObjectField } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';

import useAccountDetail from './account-detail.controller';

export interface AccountDetailProps {
    account: Account;
}

export const AccountDetail = (props: AccountDetailProps) => {
    const viewProps = useAccountDetail();

    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column w-100 h-100'>
                <ObjectField
                    fieldName={CommonMessage.FORMS.NAME}
                    value={props.account.name}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.ADDRESS}
                    value={
                        <AddressBadge
                            address={props.account.address_line_1}
                            city={props.account.city}
                            zip={props.account.zip_code}
                        />
                    }
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.COUNTRY}
                    value={props.account.country}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.CONTACT_INFO}
                    value={props.account.contact_info}
                />
                {viewProps.isDetailPage && (
                    <>
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.account.creation_timestamp}
                                    displayTime
                                />
                            }
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.account.update_timestamp}
                                    displayTime
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
};
