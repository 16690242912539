import React from 'react';

import useDeviceStatusBadge from './device-status-badge-controller';

export interface DeviceStatusBadgeProps {
    status: string;
}

export const DeviceStatusBadge = (props: DeviceStatusBadgeProps) => {
    const viewProps = useDeviceStatusBadge(props);
    return <span className={`badge badge-${viewProps.status}`}>{props.status}</span>;
};
