import { useMemo } from 'react';

import { TransactionState } from '../../transaction-state';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
}

const useTransactionStateSelect = (): ViewProps => {
    const options = useMemo(() => {
        return TransactionState.ALL.map((transactionState) => {
            return {
                value: transactionState.value,
                label: transactionState.displayName
            };
        });
    }, []);

    return { options };
};

export default useTransactionStateSelect;
