import { AsyncSelectInputSingle } from '@frontend/basic-forms';

import useSpotSelect from './spot-select.controller';

const ID = 'spot-select';
export interface SpotSelectProps {
    id?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue?: string) => void;
    value?: string;
    className?: string;
    disabled?: boolean;
    label?: React.ReactNode;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
}

const SpotSelect = (props: SpotSelectProps) => {
    const viewProps = useSpotSelect(props);

    return (
        <AsyncSelectInputSingle
            id={props.id || ID}
            label={props.label || 'SPOT'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            onChange={(newValue) => props.onChange(newValue?.value)}
            value={viewProps.value?.value}
            isDisabled={props.disabled}
            onMenuScrollToBottom={viewProps.onScrollToBottom}
            loadOptions={viewProps.search}
            isClearable={props.isClearable}
            useConditionedStyling={props.useConditionedStyling}
        />
    );
};

export default SpotSelect;
