import { BsGrid1X2Fill } from 'react-icons/bs';
import { FaUserShield } from 'react-icons/fa';
import { HiDeviceTablet } from 'react-icons/hi';
import { LuArchive, LuBox, LuBuilding, LuContact2, LuFileBadge2, LuLayoutTemplate, LuTablet, LuWorkflow } from 'react-icons/lu';

import logo from '../../../assets/images/telloport_logo_horizontal.png';
import NavbarItem from '../navbar-item/navbar-item.component';

const ID = 'sidenav-main';
export interface NavbarProps {}

const Navbar = (props: NavbarProps) => {
    return (
        <div
            id={ID}
            className='sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4'>
            <div className='sidenav-header d-flex flex-row justify-content-between align-items-center'>
                <a
                    className='navbar-brand m-0'
                    href='/'>
                    <img
                        src={logo}
                        className='navbar-brand-img h-100'
                        alt='main_logo'
                    />
                </a>
            </div>

            <hr className='horizontal dark mt-0' />

            <div
                className='collapse navbar-collapse w-auto h-100'
                id='sidenav-collapse-main'>
                <ul className='navbar-nav h-100'>
                    <NavbarItem
                        text='Account'
                        link={NavbarItems.ACCOUNT}
                        icon={<LuBuilding />}
                    />
                    <NavbarItem
                        text='Workflow'
                        link={NavbarItems.WORKFLOW}
                        icon={<LuWorkflow />}
                    />
                    <NavbarItem
                        text='Transaction'
                        link={NavbarItems.TRANSACTION}
                        icon={<LuBox />}
                    />
                    <NavbarItem
                        text='Contact'
                        link={NavbarItems.CONTACTS}
                        icon={<LuContact2 />}
                    />
                    <NavbarItem
                        text='User'
                        link={NavbarItems.USER}
                        icon={<FaUserShield />}
                    />
                    <NavbarItem
                        text='Terminal'
                        link={NavbarItems.TERMINAL}
                        icon={<LuTablet />}
                    />
                    <NavbarItem
                        text='SPOTs'
                        link={NavbarItems.SPOT}
                        icon={<BsGrid1X2Fill />}
                    />
                    <NavbarItem
                        text='SLOTs'
                        link={NavbarItems.SLOT}
                        icon={<LuArchive />}
                    />
                    <NavbarItem
                        text='Rakinda'
                        link={NavbarItems.RAKINDA}
                        icon={<HiDeviceTablet />}
                    />
                    <NavbarItem
                        text='Badges'
                        link={NavbarItems.BADGE}
                        icon={<LuFileBadge2 />}
                    />
                    <NavbarItem
                        text='Templates'
                        link={NavbarItems.TEMPLATE}
                        icon={<LuLayoutTemplate />}
                    />
                </ul>
            </div>
        </div>
    );
};

export enum NavbarItems {
    ACCOUNT = 'account',
    TRANSACTION = 'transaction',
    CONTACTS = 'contact',
    WORKFLOW = 'workflow',
    USER = 'user',
    TERMINAL = 'terminal',
    SPOT = 'spot',
    RAKINDA = 'rakinda',
    SLOT = 'slot',
    BADGE = 'badge',
    TEMPLATE = 'notification-template'
}

export default Navbar;
