import { PaginatedResponse } from '@frontend/api-utils';
import { Location } from '@frontend/common';

export interface Module {
    name: string;
    index: string;
    location: Location;
    update_timestamp: string;
    type: ModuleType;
    id: string;
    spot_id: string;
    creation_timestamp: string;
}

export interface CreateModule {
    name?: string | null;
    index?: string;
    location?: Location;
    type?: ModuleType | null;
}

export interface ModuleUpdate {
    name: string | null;
    index: string | null;
    location: Location | null;
}

export enum ModuleType {
    main_unit_5s_4m = 'main_unit_5s_4m',
    main_unit_5s_24xxs = 'main_unit_5s_24xxs',
    extension_2l_1xl = 'extension_2l_1xl',
    extension_8s_4m = 'extension_8s_4m',
    extension_16s = 'extension_16s',
    extension_24s = 'extension_24s',
    extension_48s = 'extension_48s',
    extension_8m = 'extension_8m',
    extension_4l = 'extension_4l'
}

export type ModuleListResponse = PaginatedResponse<Module>;

export enum SearchModuleQueryParams {
    PARTIAL_TEXT = 'partial_text'
}
