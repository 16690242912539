import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ModuleClient } from '../api/module-client';
import { Module, ModuleListResponse } from '../module';

interface ModuleState {
    unordered: Module[];
    modules: ModuleListResponse | null;
    spotModules: { [spotId: string]: ModuleListResponse } | null;
    status: SliceStatus;
}

const initialState: ModuleState = {
    unordered: [],
    modules: null,
    spotModules: null,
    status: SliceStatus.INIT
};

export const moduleSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        seedModules(state, action: PayloadAction<Module[]>) {
            state.unordered = [...state.unordered.filter((module) => action.payload.find((m) => m.id == module.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchModules.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchModules.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.modules == null) {
                    state.modules = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.modules.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.modules.results.length !== action.payload.count) {
                        state.modules.count = action.payload.count;
                        state.modules.results = new Array(action.payload.count);
                    }
                    state.modules.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchSpotModules.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotModules.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.queryParams.size) * toNumber(action.meta.arg.queryParams.index);
                if (state.spotModules == null) {
                    state.spotModules = { [action.meta.arg.spotId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.spotModules[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.spotModules[action.meta.arg.spotId] == undefined) {
                    state.spotModules[action.meta.arg.spotId] = { ...action.payload, results: new Array(action.payload.count) };
                    state.spotModules[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.spotModules[action.meta.arg.spotId].results.length !== action.payload.count) {
                        state.spotModules[action.meta.arg.spotId].count = action.payload.count;
                        state.spotModules[action.meta.arg.spotId].results = new Array(action.payload.count);
                    }
                    state.spotModules[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            });
    }
});

export const fetchModules = createAsyncThunk<ModuleListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchModules',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await ModuleClient.fetchModules(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotModules = createAsyncThunk<ModuleListResponse, { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }>(
    'fetchSpotModules',
    async (variables: { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await ModuleClient.fetchSpotModules(variables.spotId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const { seedModules } = moduleSlice.actions;
