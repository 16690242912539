import { ObjectDetailModal } from '../object-detail-modal.component';
import useDetailModalContainer, { DetailModalType } from './detail-modal-container.controller';

export const ID = 'detail-modal-container';

export interface Props {}

export const DetailModalContainer = (): JSX.Element => {
    const viewProps = useDetailModalContainer();
    const prefixRegex = /^([^-]*)-/;
    return (
        <div id={ID}>
            {viewProps.modals.map((modal) => (
                <ObjectDetailModal
                    key={modal}
                    id={modal.slice(prefixRegex.exec(modal)?.[0].length)}
                    detailModalType={modal.split('-')[0] as DetailModalType}
                    handleClose={viewProps.handleClose}
                    show
                />
            ))}
        </div>
    );
};
