import { PaginatedResponse } from '@frontend/api-utils';
import { ClassType } from '@frontend/common';

export enum EntityType {
    ACCOUNT = 'account',
    BADGE = 'badge',
    CONTACT = 'contact',
    RAKINDA = 'rakinda',
    TERMINAL = 'terminal',
    TRANSACTION = 'transaction',
    USER = 'user',
    SPOT = 'spot',
    SLOT = 'slot',
    WORKFLOW = 'workflow'
}

export enum EventLevel {
    DEBUG = 'debug',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    ALERT = 'alert'
}

export enum EventType {
    TRANSACTION_STATE_CHANGED = 'transaction_state_changed',
    NEW_RAKINDA_REGISTERED = 'new_rakinda_registered',
    RAKINDA_HEARTBEAT_MISSED = 'rakinda_heartbeat_missed',
    RAKINDA_HEARTBEAT_RECOVERED = 'rakinda_heartbeat_recovered',
    RAKINDA_MASTER_PIN_USED = 'rakinda_master_pin_code_used',
    RAKINDA_ACCESS_GRANTED = 'rakinda_access_granted',
    RAKINDA_ACCESS_DENIED = 'rakinda_access_denied',
    EMAIL_SENT = 'email_sent'
}

export interface Event {
    level: EventLevel;
    type: EventType;
    data: string;
    id: string;
    creation_timestamp: string;
}

export interface EventMessage {
    message: string;
    level: { severity: ClassType; icon: React.ReactNode };
    type: string;
    timestamp: string;
    id: string;
}

export interface EventEntity {
    id: string;
    event_id: string;
    entity_type: EntityType;
    entity_id: string;
}

export type EventListResponse = PaginatedResponse<Event>;

export type EventEntitiesListResponse = PaginatedResponse<EventEntity>;
