import React from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

import usePasswordInput from './password-input.controller';

export const ID = 'password-input';
export interface Props {
    compareMultiple?: string[];
    compare?: string;
    strength?: boolean;
    toRepeat?: string;
    required?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    id?: string;
    className?: string;
}

export enum PasswordInputError {
    NONE,
    REQUIRED,
    STRENGTH,
    SIMILARITY,
    REPEAT_NOT_IDENTICAL
}

export const PasswordInput = (props: Props) => {
    const viewProps = usePasswordInput(props);

    return (
        <div
            id={ID}
            className={'form-group ' + props.className}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                {props.label ? props.label : 'Password'}
            </label>

            <div className='input-group'>
                <input
                    className={viewProps.getClassName()}
                    autoFocus={props.autoFocus}
                    data-testid='passwordInput'
                    id={props.id}
                    value={props.value}
                    autoComplete='current-password'
                    type={viewProps.visible ? 'text' : 'password'}
                    onFocus={() => {
                        viewProps.changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    required={props.required}
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.value);
                        viewProps.changeStrength(viewProps.calcStrengthPercentage(event.target.value));
                        viewProps.isValid();
                    }}
                />
                <button
                    className='btn btn-outline-secondary mb-0'
                    type='button'
                    id='button-addon2'
                    onClick={() => viewProps.changeVisible(!viewProps.visible)}>
                    {viewProps.visible ? <BsEyeSlash /> : <BsEye />}
                </button>
            </div>
            {props.strength && (
                <div className='progress mt-2'>
                    <div
                        className={'progress-bar bg-gradient-' + viewProps.color}
                        role='progressbar'
                        aria-valuenow={viewProps.strength}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={viewProps.style}
                    />
                </div>
            )}
            {!(props.compare == undefined && props.compareMultiple == undefined) && (
                <span className='mt-2'>
                    <small>
                        Your password should contain at least 1 small letter, 1 capital letter, 1 number, 1 symbol and be a minimum of 8 characters long.
                    </small>
                </span>
            )}

            {viewProps.valid === false && viewProps.touched === true && (
                <>
                    {props.errorMessage === undefined ? (
                        <span className='badge bg-gradient-danger mt-2'>
                            <small>
                                {viewProps.error === PasswordInputError.REQUIRED && 'A password is required.'}
                                {viewProps.error === PasswordInputError.STRENGTH && 'Make sure your password meets all strength requirements.'}
                                {viewProps.error === PasswordInputError.SIMILARITY &&
                                    'Your password shares similarity with other information you have given. Please make it more random.'}
                                {viewProps.error === PasswordInputError.REPEAT_NOT_IDENTICAL && 'Both passwords should be the same.'}
                            </small>
                        </span>
                    ) : (
                        <span className='badge bg-gradient-danger mt-2'>
                            <small>{props.errorMessage}</small>
                        </span>
                    )}
                </>
            )}
        </div>
    );
};
