import { LuBuilding } from 'react-icons/lu';

import { DetailModalType } from '../../modals/detail-modal-container/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useAccountBadge from './account-badge.controller';

export interface AccountBadgeProps {
    id?: string;
    name?: string;
}

const AccountBadge = (props: AccountBadgeProps) => {
    const viewProps = useAccountBadge(props);

    return (
        <BadgeTemplate
            type={DetailModalType.ACCOUNT}
            objectId={props.id}
            disabled={viewProps.disabled}>
            <LuBuilding className='me-2' />
            {viewProps.displayName ? (
                viewProps.displayName
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </BadgeTemplate>
    );
};

export default AccountBadge;
