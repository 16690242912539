import { Spot, fetchSpot } from '@frontend/spot';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { SlotDetailProps } from './slot-detail.component';

interface ViewProps {
    spot: Spot | undefined;
    isDetailPage: boolean;
}

const useSlotDetail = (props: SlotDetailProps): ViewProps => {
    const spotState = useAppSelector((state) => state.spots);
    const dispatch = useAppDispatch();
    const [spot, changeSpot] = useState<Spot | undefined>(undefined);
    const { pathname } = useLocation();

    useEffect(() => {
        const found = spotState.unordered.find((s) => s.id == props.slot.spot_id);
        if (found) {
            changeSpot(found);
        } else dispatch(fetchSpot(props.slot.spot_id));
    }, [props.slot, spotState.unordered]);

    return {
        spot,
        isDetailPage: pathname.includes('detail') && pathname.includes('slot')
    };
};

export default useSlotDetail;
