import { Terminal, fetchTerminal } from '@frontend/terminal-service';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TerminalDetailModalWrapperProps } from './terminal-detail-modal-wrapper.component';

interface ViewProps {
    terminal?: Terminal;
}

const useTerminalDetailModalWrapper = (props: TerminalDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const terminals = useAppSelector((state) => state.terminals);
    const [terminal, changeTerminal] = useState<Terminal | undefined>(undefined);

    useEffect(() => {
        const found = terminals.unordered.find((t) => t.id == props.id);
        if (found) changeTerminal(found);
        else dispatch(fetchTerminal(props.id));
    }, [props.id, terminals]);

    return { terminal };
};

export default useTerminalDetailModalWrapper;
