import { DefaultModalProps, LoadingModal, ModalType } from '@frontend/elements';

import TransactionDetailModal from '../transaction-detail-modal/transaction-detail-modal.component';
import useTransactionDetailModalWrapper from './transaction-detail-modal-wrapper.controller';

export interface TransactionDetailModalWrapperProps extends DefaultModalProps {
    id: string;
}

const TransactionDetailModalWrapper = (props: TransactionDetailModalWrapperProps): JSX.Element => {
    const viewProps = useTransactionDetailModalWrapper(props);

    if (!viewProps.transaction) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <TransactionDetailModal
            transaction={viewProps.transaction}
            customWidth={40}
            {...props}
        />
    );
};

export default TransactionDetailModalWrapper;
