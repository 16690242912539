import { useLocation } from 'react-router-dom';

interface ViewProps {
    isDetailPage: boolean;
}

const useTerminalDetail = (): ViewProps => {
    const { pathname } = useLocation();

    return {
        isDetailPage: pathname.includes('detail') && pathname.includes('terminal')
    };
};

export default useTerminalDetail;
