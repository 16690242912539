import { useMemo } from 'react';

import { ModuleType } from '../../module';

interface ViewProps {
    options: { value: string; label: string }[];
}

const useModuleTypeSelect = (): ViewProps => {
    const options = useMemo(() => {
        return Object.values(ModuleType).map((type) => ({
            value: type,
            label: type
        }));
    }, []);
    return { options };
};

export default useModuleTypeSelect;
