import { deleteUser } from '@frontend/user';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../redux/store';
import { UserDetailModalProps } from './user-detail-modal.component';

interface ViewProps {
    removeUser: (id: string) => void;
    showUpdateModal: boolean;
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    viewDetails: () => void;
}

export const useUserDetailModal = (props: UserDetailModalProps): ViewProps => {
    const dispatch = useAppDispatch();
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const navigate = useNavigate();

    function removeUser(id: string) {
        dispatch(deleteUser(id));
    }

    const viewDetails = () => {
        navigate(`/user/${props.user?.id}/detail`);
    };

    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        removeUser,
        showDeleteModal,
        showUpdateModal,
        viewDetails
    };
};
