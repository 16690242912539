import React from 'react';
import { HeaderGroup } from 'react-table';

interface Props {
    column: HeaderGroup<any>;
    onSort?: (columnId: string, isSortedDesc: boolean | undefined) => void;
}

export function THeaderItem(props: Props) {
    if (props.column.id === 'selection' || props.column.id === 'expand') {
        return (
            <th
                className='ps-2'
                {...props.column.getHeaderProps(props.column.getSortByToggleProps())}>
                {props.column.render('Header')}
            </th>
        );
    }
    return (
        <th
            {...props.column.getHeaderProps(props.column.getSortByToggleProps())}
            className={
                'text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 ' +
                (props.column.isSorted ? (props.column.isSortedDesc ? 'desc' : 'asc') : '')
            }
            onClick={() => {
                let nextSortDirection;
                if (props.column.isSorted === false) {
                    nextSortDirection = false;
                } else if (props.column.isSortedDesc === true) {
                    nextSortDirection = undefined;
                } else {
                    nextSortDirection = true;
                }

                props.column.toggleSortBy(nextSortDirection);
                props.onSort && props.onSort(props.column.id, nextSortDirection);
            }}>
            <a className='dataTable-sorter'>{props.column.render('Header')}</a>
        </th>
    );
}
