import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateSlot, SearchSlotQueryParams, Slot, SlotListResponse, UpdateSlot } from '../slot';

const endpoint = '/slot-service/api/v1';

export class SlotClient extends APIClient {
    public static async fetchSlots(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, DefaultQueryParams>(`${endpoint}/slots`, queryParams);
    }

    public static async searchSlots(queryParams?: ApiQueryParams<DefaultQueryParams | SearchSlotQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, DefaultQueryParams | SearchSlotQueryParams>(`${endpoint}/slots-search`, queryParams);
    }

    public static async resolveSlots(slotIds: string[]): Promise<SlotListResponse> {
        const response = await this.post(`${endpoint}/slots-resolve`, { ids: slotIds });
        return await this.handleResponse<SlotListResponse>(response);
    }

    public static async fetchSpotSlots(spotId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, DefaultQueryParams>(`${endpoint}/spots/${spotId}/slots`, queryParams);
    }

    public static async fetchSpotModuleSlots(spotId: string, moduleId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, DefaultQueryParams>(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots`, queryParams);
    }

    public static async postSpotModuleSlot(spotId: string, moduleId: string, slot: CreateSlot): Promise<Slot> {
        const response = await this.post(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots`, slot);
        return await this.handleResponse<Slot>(response);
    }

    public static async fetchSpotModuleSlot(spotId: string, moduleId: string, slotId: string): Promise<Slot> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}`);
        return await this.handleResponse<Slot>(response);
    }

    public static async patchSpotModuleSlot(spotId: string, moduleId: string, slotId: string, body: UpdateSlot): Promise<Slot> {
        const response = await this.patch(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}`, body);
        return await this.handleResponse<Slot>(response);
    }

    public static async deleteSpotModuleSlot(spotId: string, moduleId: string, slotId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}`);
        return await this.handleVoidResponse(response);
    }
}
