export enum InputClass {
    normal = 'form-control',
    success = 'form-control is-valid',
    error = 'form-control is-invalid'
}

export const getClassName = (touched: boolean, valid: boolean, value?: string): string => {
    if (!touched) {
        return InputClass.normal; // Untouched: always return normal
    }

    if (!valid) {
        return InputClass.error; // Invalid: always return error
    }
    // Handle both empty and non-empty values for success state
    return value != undefined && value.trim() !== '' ? InputClass.success : InputClass.normal;
};

export function getInputClassMultiSelect(touched: boolean, valid: boolean, values?: string[]): string {
    if (values == undefined || values.length === 0) return getClassName(touched, valid);
    return getClassName(touched, valid, values[0]);
}
