import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { SlotClient } from '../api/slot-client';
import { CreateSlot, Slot, SlotListResponse } from '../slot';

interface SlotState {
    unordered: Slot[];
    slots: SlotListResponse | null;
    spotSlots: { [spotId: string]: SlotListResponse } | null;
    spotModuleSlots: { [spotId: string]: { [moduleId: string]: SlotListResponse } } | null;
    status: SliceStatus;
}

const initialState: SlotState = {
    unordered: [],
    slots: null,
    spotSlots: null,
    spotModuleSlots: null,
    status: SliceStatus.INIT
};

export const slotSlice = createSlice({
    name: 'slots',
    initialState,
    reducers: {
        seedSlots(state, action: PayloadAction<Slot[]>) {
            state.unordered = [...state.unordered.filter((slot) => action.payload.find((s) => s.id == slot.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSlots.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.slots == null) {
                    state.slots = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.slots.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.slots.results.length !== action.payload.count) {
                        state.slots.count = action.payload.count;
                        state.slots.results = new Array(action.payload.count);
                    }
                    state.slots.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchSpotSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotSlots.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1);
                if (state.spotSlots == null) {
                    state.spotSlots = { [action.meta.arg.spotId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.spotSlots[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.spotSlots[action.meta.arg.spotId] == undefined) {
                    state.spotSlots[action.meta.arg.spotId] = { ...action.payload, results: new Array(action.payload.count) };
                    state.spotSlots[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.spotSlots[action.meta.arg.spotId].results.length !== action.payload.count) {
                        state.spotSlots[action.meta.arg.spotId].count = action.payload.count;
                        state.spotSlots[action.meta.arg.spotId].results = new Array(action.payload.count);
                    }
                    state.spotSlots[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchSpotModuleSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotModuleSlots.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1);
                if (state.spotModuleSlots == null) {
                    state.spotModuleSlots = {
                        [action.meta.arg.spotId]: { [action.meta.arg.moduleId]: { ...action.payload, results: new Array(action.payload.count) } }
                    };
                    state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else {
                    if (state.spotModuleSlots[action.meta.arg.spotId] == undefined) {
                        state.spotModuleSlots[action.meta.arg.spotId] = {};
                    }
                    if (state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId] == undefined) {
                        state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId] = {
                            ...action.payload,
                            results: new Array(action.payload.count)
                        };
                        state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    } else {
                        if (state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].results.length !== action.payload.count) {
                            state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].count = action.payload.count;
                            state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].results = new Array(action.payload.count);
                        }
                        state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    }
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(deleteSpotModuleSlot.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteSpotModuleSlot.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.slots) {
                    const found = state.slots.results.find((s) => s.id == action.meta.arg.slotId);
                    if (found == undefined) return;
                    state.slots.results.splice(state.slots.results.indexOf(found), 1);
                }
                if (state.spotSlots && state.spotSlots[action.meta.arg.spotId]) {
                    const found = state.spotSlots[action.meta.arg.spotId].results.find((s) => s.id == action.meta.arg.slotId);
                    if (found == undefined) return;
                    state.spotSlots[action.meta.arg.spotId].results.splice(state.spotSlots[action.meta.arg.spotId].results.indexOf(found), 1);
                }
                if (
                    state.spotModuleSlots &&
                    state.spotModuleSlots[action.meta.arg.spotId] &&
                    state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId]
                ) {
                    const found = state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].results.find((s) => s.id == action.meta.arg.slotId);
                    if (found == undefined) return;
                    state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].results.splice(
                        state.spotModuleSlots[action.meta.arg.spotId][action.meta.arg.moduleId].results.indexOf(found),
                        1
                    );
                }
            })
            .addCase(postSpotModuleSlot.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(postSpotModuleSlot.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = [...state.unordered.filter((s) => action.payload.id != s.id), action.payload];
            });
    }
});

export const fetchSlots = createAsyncThunk<SlotListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchSlots',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await SlotClient.fetchSlots(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotSlots = createAsyncThunk<SlotListResponse, { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }>(
    'fetchSpotSlots',
    async (variables: { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await SlotClient.fetchSpotSlots(variables.spotId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotModuleSlots = createAsyncThunk<SlotListResponse, { spotId: string; moduleId: string; queryParams: ApiQueryParams<DefaultQueryParams> }>(
    'fetchSpotModuleSlots',
    async (variables: { spotId: string; moduleId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await SlotClient.fetchSpotModuleSlots(variables.spotId, variables.moduleId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteSpotModuleSlot = createAsyncThunk<void, { spotId: string; moduleId: string; slotId: string }>(
    'deleteSpotModuleSlot',
    async (variables: { spotId: string; moduleId: string; slotId: string }, { rejectWithValue }) => {
        try {
            return await SlotClient.deleteSpotModuleSlot(variables.spotId, variables.moduleId, variables.slotId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const postSpotModuleSlot = createAsyncThunk<Slot, { spotId: string; moduleId: string; slot: CreateSlot }>(
    'postSpotModuleSlot',
    async (variables: { spotId: string; moduleId: string; slot: CreateSlot }, { rejectWithValue }) => {
        try {
            return await SlotClient.postSpotModuleSlot(variables.spotId, variables.moduleId, variables.slot);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
export const { seedSlots } = slotSlice.actions;
