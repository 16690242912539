import { terminalRequest } from '@frontend/terminal-service';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../redux/store';
import { TerminalBadgeProps } from './terminal-badge.component';

interface ViewProps {
    displayName?: string;
    disabled: boolean;
}

const useTerminalBadge = (props: TerminalBadgeProps): ViewProps => {
    const terminalState = useAppSelector((state) => state.terminals);
    const [displayName, changeDisplayName] = useState<string | undefined>(props.name);
    const [requested, changeRequested] = useState<boolean>(false);
    const [disabled, changeDisabled] = useState<boolean>(false);
    useEffect(() => {
        if (displayName == undefined && props.id != undefined) {
            const found = terminalState.unordered.find((t) => t.id == props.id);
            if (found) {
                changeDisplayName(found.name);
            } else if (requested == false) {
                changeRequested(true);
                terminalRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        }
    }, [terminalState, props.id]);

    return { displayName, disabled };
};

export default useTerminalBadge;
