import { Badge, fetchAccountBadge } from '@frontend/badge';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { BadgeDetailModalWrapperProps } from './badge-detail-modal-wrapper.component';

interface ViewProps {
    badge?: Badge;
}

const useBadgeDetailModalWrapper = (props: BadgeDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const badges = useAppSelector((state) => state.badges);
    const [badge, changeBadge] = useState<Badge | undefined>(undefined);

    useEffect(() => {
        const ids = props.id.split('-account-');
        const found = badges.unordered.find((b) => b.id == ids[0]);
        if (found) changeBadge(found);
        else dispatch(fetchAccountBadge({ accountId: ids[1], badgeId: ids[0] }));
    }, [props.id, badges]);

    return {
        badge
    };
};

export default useBadgeDetailModalWrapper;
