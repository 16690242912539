import { EventMessage } from '@frontend/event-service';
import { Table } from '@frontend/table';
import React from 'react';
import { FaLink } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import EventElement from './event-element';
import useEventTable from './event-table.controller';

export interface EventTableProps {
    data: EventMessage[];
    page: number;
    pageSize: number;
    changePage: (value: React.SetStateAction<number>) => void;
    changePageSize: (value: React.SetStateAction<number>) => void;
    onClick?: (element: EventMessage) => void;
}

export const EventTable = (props: EventTableProps) => {
    const viewProps = useEventTable(props);

    return (
        <Table
            columns={viewProps.columns}
            RowElement={EventElement}
            data={props.data}
            pageCount={viewProps.pageCount}
            page={props.changePage}
            pageSize={props.changePageSize}
            onSelectElement={props.onClick}
        />
    );
};

export const EventLink = (props: { row: Row<EventMessage> }) => {
    const navigate = useNavigate();

    return (
        <button className='btn btn-outline-secondary px-1 pb-1 pt-0 my-0 mx-1'>
            <FaLink onClick={() => navigate(`/event/${props.row.original.id}/entities`)} />
        </button>
    );
};
