export enum EntityType {
    SPOT = 'spot',
    SLOT = 'slot',
    ACCOUNT = 'account',
    CONTACT = 'contact',
    RAKINDA = 'rakinda',
    TERMINAL = 'terminal',
    TRANSACTION = 'transaction',
    USER = 'user',
    ALL = '*',
    UNKNOWN = ''
}
