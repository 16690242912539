import { Slot, slotRequest } from '@frontend/slot';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../../redux/store';
import { SlotDetailModalWrapperProps } from './slot-detail-modal-wrapper.component';

interface ViewProps {
    slot?: Slot;
}

const useSlotDetailModalWrapper = (props: SlotDetailModalWrapperProps): ViewProps => {
    const slots = useAppSelector((state) => state.slots);
    const [slot, changeSlot] = useState<Slot | undefined>(undefined);

    useEffect(() => {
        const found = slots.unordered.find((s) => s.id === props.id);
        if (found) changeSlot(found);
        else slotRequest(props.id);
    }, [props.id, slots]);

    return {
        slot
    };
};

export default useSlotDetailModalWrapper;
