import { User, fetchUser } from '@frontend/user';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { UserDetailModalWrapperProps } from './user-detail-modal-wrapper.component';

interface ViewProps {
    user?: User;
}

const useUserDetailModalWrapper = (props: UserDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const users = useAppSelector((state) => state.users);
    const [user, changeUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        const found = users.unordered.find((u) => u.id === props.id);
        if (found) changeUser(found);
        else dispatch(fetchUser(props.id));
    }, [props.id, users]);

    return { user };
};

export default useUserDetailModalWrapper;
