import { useDetailPage } from '@frontend/common';
import { AddressBadge, DateBadge, NameBadge, ObjectField } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { User } from '@frontend/user';

export interface UserDetailProps {
    user: User;
}

export const UserDetail = (props: UserDetailProps) => {
    const viewProps = useDetailPage('user');
    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column h-100 w-100'>
                <ObjectField
                    fieldName={CommonMessage.FORMS.NAME}
                    value={
                        <NameBadge
                            firstName={props.user.first_name}
                            lastName={props.user.last_name}
                        />
                    }
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.EMAIL()}
                    value={props.user.email}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.ADDRESS}
                    value={
                        <AddressBadge
                            address={props.user.address_line_1}
                            city={props.user.city}
                            zip={props.user.zip_code}
                        />
                    }
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.COUNTRY}
                    value={props.user.country}
                />
                {viewProps.isDetailPage && (
                    <>
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.user.creation_timestamp}
                                    displayTime
                                />
                            }
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.user.update_timestamp}
                                    displayTime
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
};
