import { accountRequest } from '@frontend/account';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../redux/store';
import { AccountBadgeProps } from './account-badge.component';

interface ViewProps {
    displayName?: string;
    disabled: boolean;
}

const useAccountBadge = (props: AccountBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const accountState = useAppSelector((state) => state.accounts);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | undefined>(props.name);
    useEffect(() => {
        if (props.id != undefined) {
            const found = accountState.unordered.find((ac) => ac.id == props.id);
            if (found) {
                changeDisplayName(found.name);
            } else if (requested == false) {
                changeRequested(true);
                accountRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        }
    }, [accountState, props.id]);

    return {
        displayName,
        disabled
    };
};

export default useAccountBadge;
