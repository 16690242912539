import { useNavigate } from 'react-router-dom';

import { SlotDetailModalProps } from './slot-detail-modal.component';

interface ViewProps {
    viewDetails: () => void;
}

export const useSlotDetailModal = (props: SlotDetailModalProps): ViewProps => {
    const navigate = useNavigate();

    const viewDetails = () => {
        navigate(`/slot/${props.slot?.id}/detail`);
    };

    return {
        viewDetails
    };
};
