import { Account, fetchAccount } from '@frontend/account';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { AccountDetailModalWrapperProps } from './account-detail-modal-wrapper.component';

interface ViewProps {
    account?: Account;
}

const useAccountDetailModalWrapper = (props: AccountDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const accounts = useAppSelector((state) => state.accounts);
    const [account, changeAccount] = useState<Account | undefined>(undefined);

    useEffect(() => {
        const found = accounts.unordered.find((a) => a.id === props.id);
        if (found) changeAccount(found);
        else dispatch(fetchAccount(props.id));
    }, [props.id, accounts]);

    return { account };
};

export default useAccountDetailModalWrapper;
