import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateTransactionField, TransactionField, TransactionFieldListResponse, UpdateTransactionField } from '../transaction-field';

const endpoint = '/transaction-service/api/v1';

export class TransactionFieldClient extends APIClient {
    public static async fetchSpotTransactionFields(
        spotId: string,
        transactionId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<TransactionFieldListResponse> {
        return await this.apiPaginated<TransactionFieldListResponse, DefaultQueryParams>(
            `${endpoint}/spots/${spotId}/transactions/${transactionId}/fields`,
            queryParams
        );
    }

    public static async postSpotTransactionField(spotId: string, transactionId: string, transactionField: CreateTransactionField): Promise<TransactionField> {
        const response = await this.post(`${endpoint}/spots/${spotId}/transactions/${transactionId}/fields`, transactionField);
        return await this.handleResponse<TransactionField>(response);
    }

    public static async fetchSpotTransactionField(spotId: string, transactionId: string, transactionFieldId: string): Promise<TransactionField> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/transactions/${transactionId}/fields/${transactionFieldId}`);
        return await this.handleResponse<TransactionField>(response);
    }

    public static async patchSpotTransactionField(
        spotId: string,
        transactionId: string,
        transactionFieldId: string,
        transactionField: UpdateTransactionField
    ): Promise<TransactionField> {
        const response = await this.patch(`${endpoint}/spots/${spotId}/transactions/${transactionId}/fields/${transactionFieldId}`, transactionField);
        return await this.handleResponse<TransactionField>(response);
    }

    public static async deleteSpotTransactionField(spotId: string, transactionId: string, transactionFieldId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/spots/${spotId}/transactions/${transactionId}/fields/${transactionFieldId}`);
        return await this.handleVoidResponse(response);
    }
}
