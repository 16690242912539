import { rakindaRequest } from '@frontend/rakinda';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../redux/store';
import { RakindaBadgeProps } from './rakinda-badge.component';

interface ViewProps {
    displayName?: string;
    disabled: boolean;
}

const useRakindaBadge = (props: RakindaBadgeProps): ViewProps => {
    const rakindas = useAppSelector((state) => state.rakindas);
    const [displayName, changeDisplayName] = useState<string | undefined>(undefined);
    const [requested, changeRequested] = useState<boolean>(false);
    const [disabled, changeDisabled] = useState<boolean>(false);
    useEffect(() => {
        if (props.id != undefined) {
            const found = rakindas.unordered.find((r) => r.id == props.id);
            if (found) {
                changeDisplayName(found.mac_address);
            } else if (requested == false) {
                changeRequested(false);
                rakindaRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        }
    }, [rakindas, props.id]);

    return {
        displayName,
        disabled
    };
};

export default useRakindaBadge;
