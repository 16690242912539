export interface WorkflowFieldTemplate {
    key: WorkflowFieldTemplateKey;
    type: string;
    description?: string; //check if the frontend has a enough context to generate its own description -> then this field can be removed
    value: string | null;

    visible: boolean;
    editable: boolean;
}

export interface IncompleteWorkflowField extends WorkflowFieldTemplate {
    account_id: string;
    workflow_id: string;
    step_id: string;
}

export enum WorkflowFieldTemplateKey {
    CONTACT = 'contact_id',
    SENDER_ID = 'sender_id',
    RECEIVER_ID = 'receiver_id',
    NOTIFICATION_TEMPLATE = 'template_id',
    DELAY_MINUTES = 'delay_minutes',
    TIME_OUT_HOURS = 'time_out_hours',
    REPEAT_HOURS = 'repeat_hours'
}
