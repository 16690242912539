import { ClassType } from '@frontend/common';
import { Contact } from '@frontend/contact';
import { DefaultModal, DefaultModalProps, ModalFooter, ModalHeader, ModalType } from '@frontend/elements';
import { FaEye } from 'react-icons/fa';

import { ContactDetail } from '../contact-detail.component';
import { useContactDetailModal } from './contact-detail-modal.controller';

const ID = 'contact-detail-modal';

export interface ContactDetailModalProps extends DefaultModalProps {
    contact: Contact;
}

const ContactDetailModal = (props: ContactDetailModalProps) => {
    const viewProps = useContactDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id='contact-detail-modal-header'
                handleClose={props.handleClose}
                title={`${props.contact.first_name} ${props.contact.last_name}`}
            />
            <div className='modal-body'>
                <ContactDetail contact={props.contact} />
            </div>
            <ModalFooter
                buttons={[
                    {
                        text: 'View details',
                        onClick: () => viewProps.viewDetails(),
                        icon: FaEye,
                        type: ClassType.PRIMARY
                    }
                ]}
            />
        </DefaultModal>
    );
};

export default ContactDetailModal;
