import { ClassType } from '@frontend/common';
import { DefaultModal, DefaultModalProps, ModalFooter, ModalHeader, ModalType } from '@frontend/elements';
import { Slot } from '@frontend/slot';
import { FaEye } from 'react-icons/fa';

import { SlotDetail } from '../slot-detail.component';
import { useSlotDetailModal } from './slot-detail-modal.controller';

const ID = 'slot-detail-modal';

export interface SlotDetailModalProps extends DefaultModalProps {
    slot: Slot;
}

const SlotDetailModal = (props: SlotDetailModalProps) => {
    const viewProps = useSlotDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id={ID + '-header'}
                handleClose={props.handleClose}
                title={props.slot.id}
            />
            <div className='modal-body'>
                <SlotDetail slot={props.slot} />
            </div>
            <ModalFooter
                buttons={[
                    {
                        text: 'View details',
                        onClick: () => viewProps.viewDetails(),
                        type: ClassType.PRIMARY,
                        icon: FaEye
                    }
                ]}
            />
        </DefaultModal>
    );
};

export default SlotDetailModal;
