import { AddressBadge, DateBadge, ObjectField } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Rakinda } from '@frontend/rakinda';

import useRakindaDetail from './rakinda-detail.controller';

export interface RakindaDetailProps {
    rakinda: Rakinda;
}

const RakindaDetail = (props: RakindaDetailProps) => {
    const viewProps = useRakindaDetail();
    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column h-100 w-100'>
                <ObjectField
                    fieldName={CommonMessage.FORMS.NAME}
                    value={props.rakinda.name}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.ADDRESS}
                    value={
                        <AddressBadge
                            address={props.rakinda.address_line_1}
                            city={props.rakinda.city}
                            zip={props.rakinda.zip_code}
                        />
                    }
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.COUNTRY}
                    value={props.rakinda.country}
                />
                <ObjectField
                    fieldName={CommonMessage.FORMS.CONTACT}
                    value={props.rakinda.contact}
                />
                {viewProps.isDetailPage && (
                    <>
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.RAKINDA.MAC_ADDRESS}
                            value={props.rakinda.mac_address}
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.RAKINDA.MASTER_PIN_CODE}
                            value={props.rakinda.master_pin_code}
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.rakinda.creation_timestamp}
                                    displayTime
                                />
                            }
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                            value={
                                <DateBadge
                                    date={props.rakinda.update_timestamp}
                                    displayTime
                                />
                            }
                        />
                        <ObjectField
                            fieldName={CommonMessage.OBJECTS.RAKINDA.HEARTBEAT}
                            value={
                                <DateBadge
                                    date={props.rakinda.heartbeat_timestamp}
                                    displayTime
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default RakindaDetail;
