import { AsyncSelectInputSingle } from '@frontend/basic-forms';

import useUserSelect from './user-select.controller';

const ID = 'user-select';
export interface UserSelectProps {
    id?: string;
    label?: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue?: string) => void;
    value?: string;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
}

const UserSelect = (props: UserSelectProps) => {
    const viewProps = useUserSelect(props);

    return (
        <AsyncSelectInputSingle
            id={props.id || ID}
            label={props.label || 'User'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            onChange={(newValue) => props.onChange(newValue?.value)}
            value={viewProps.value?.value}
            isDisabled={props.disabled}
            onMenuScrollToBottom={viewProps.onScrollToBottom}
            loadOptions={viewProps.search}
            isClearable={props.isClearable}
            useConditionedStyling={props.useConditionedStyling}
        />
    );
};

export default UserSelect;
