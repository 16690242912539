import { DefaultModalProps } from '@frontend/elements';

import BadgeDetailModalWrapper from '../../account-badges/badge-detail/badge-detail-modal-wrapper/badge-detail-modal-wrapper.component';
import AccountDetailModalWrapper from '../../accounts/account-detail/account-detail-modal-wrapper/account-detail-modal-wrapper.component';
import ContactDetailModalWrapper from '../../contacts/contact-detail/contact-detail-modal-wrapper/contact-detail-modal-wrapper.component';
import RakindaDetailModalWrapper from '../../rakindas/rakinda-detail/rakinda-detail-modal-wrapper/rakinda-detail-modal-wrapper.component';
import SlotDetailModalWrapper from '../../slots/slot-detail/slot-detail-modal-wrapper/slot-detail-modal-wrapper.component';
import TerminalDetailModalWrapper from '../../terminals/terminal-detail/terminal-detail-modal-wrapper/terminal-detail-modal-wrapper.component';
import TransactionDetailModalWrapper from '../../transactions/transaction-detail-modal-wrapper/transaction-detail-modal-wrapper.component';
import UserDetailModalWrapper from '../../users/user-detail/user-detail-modal-wrapper/user-detail-modal-wrapper.component';
import { DetailModalType } from './detail-modal-container/detail-modal-container.controller';

export const ID = 'detail-modal';

export interface Props extends DefaultModalProps {
    detailModalType: DetailModalType;
    id: string;
}

export const ObjectDetailModal = (props: Props): JSX.Element => {
    return (
        <div id={ID}>
            {props.detailModalType == DetailModalType.CONTACT && <ContactDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.USER && <UserDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.ACCOUNT && <AccountDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.TERMINAL && <TerminalDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.RAKINDA && <RakindaDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.SLOT && <SlotDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.BADGE && <BadgeDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.TRANSACTION && <TransactionDetailModalWrapper {...props} />}
        </div>
    );
};
