import { useMemo } from 'react';

import { WorkflowButtonType } from '../../workflow-button';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
}

const useWorkflowButtonTypeSelect = (): ViewProps => {
    const options = useMemo(() => {
        return Object.values(WorkflowButtonType).map((type) => {
            return {
                value: type,
                label: type
            };
        });
    }, []);
    return { options };
};

export default useWorkflowButtonTypeSelect;
