import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { EventClient } from '../api/event-client';
import { EventEntitiesListResponse, EventListResponse } from '../event';

interface EventState {
    events: {
        [entityType: string]: { [entityId: string]: EventListResponse };
    };
    status: SliceStatus;
    eventEntities: { [eventId: string]: EventEntitiesListResponse };
    entityStatus: SliceStatus;
}

const initialState: EventState = {
    events: {},
    eventEntities: {},
    entityStatus: SliceStatus.INIT,
    status: SliceStatus.INIT
};

export const eventSlice = createSlice({
    name: 'eventSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEntityEvents.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchEntityEvents.fulfilled, (state, action) => {
                const entityType = action.meta.arg.entityType;
                const entityID = action.meta.arg.entityId;
                const startPos = toNumber(action.meta.arg.queryParams?.size) * toNumber(action.meta.arg.queryParams?.index);
                if (state.events[entityType] !== undefined) {
                    if (state.events[entityType][entityID] !== undefined) {
                        if (state.events[entityType][entityID].results.length !== action.payload.count) {
                            state.events[entityType][entityID].count = action.payload.count;
                            state.events[entityType][entityID].results = new Array(action.payload.count);
                        }
                        state.events[entityType][entityID].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        state.events[entityType][entityID] = { ...action.payload, results: new Array(action.payload.count) };
                        state.events[entityType][entityID].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                } else {
                    state.events[entityType] = { [entityID]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.events[entityType][entityID].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchEventEntities.pending, (state) => {
                state.entityStatus = SliceStatus.LOADING;
            })
            .addCase(fetchEventEntities.fulfilled, (state, action) => {
                const { eventId } = action.meta.arg;
                const startPos = toNumber(action.meta.arg.queryParams?.size) * toNumber(action.meta.arg.queryParams?.index);
                if (!state.eventEntities[eventId]) {
                    state.eventEntities = { [eventId]: { count: action.payload.count, results: new Array(action.payload.count) } };
                    state.eventEntities[eventId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.eventEntities[eventId].results.length != action.payload.count) {
                        state.eventEntities[eventId].count = action.payload.count;
                        state.eventEntities[eventId].results = new Array(action.payload.count);
                    }
                    state.eventEntities[eventId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
            });
    }
});

export const fetchEntityEvents = createAsyncThunk<
    EventListResponse,
    { entityType: string; entityId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }
>('fetchEntityEvents', async (variables: { entityType: string; entityId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
    try {
        return await EventClient.fetchEntityEvents(variables.entityType, variables.entityId, variables.queryParams);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchEventEntities = createAsyncThunk<EventEntitiesListResponse, { eventId: string; queryParams: ApiQueryParams<DefaultQueryParams> }>(
    'fetchEventEntities',
    async (variables: { eventId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await EventClient.fetchEventEntities(variables.eventId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
