import { TransactionSliceState, transactionRequest } from '@frontend/transaction';
import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useSelector } from 'react-redux';

import { TransactionBadgeProps } from './transaction-badge.component';

interface ViewProps {
    displayName?: string;
    disabled: boolean;
}

export const useTransactionBadge = (props: TransactionBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const transactionState: TransactionSliceState = useSelector((state: any) => state.transactions);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | undefined>(props.name);

    useEffect(() => {
        if (props.id != undefined) {
            const found = transactionState.unordered.find((t: any) => t.id == props.id);
            if (found) {
                changeDisplayName(found.tracking_number);
            } else if (requested == false) {
                changeRequested(true);
                transactionRequest(props.id);
            }

            if (requested && !found) {
                changeDisplayName('Not found');
            }
        }

        if (!props.id) changeDisabled(true);
    }, [transactionState, props.id]);

    return {
        disabled,
        displayName
    };
};
