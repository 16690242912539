import { PubSubConnection } from '@frontend/pub-sub';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { WorkflowButtonEventHandler } from './workflow-button-event-handler';
import { WorkflowEventHandler } from './workflow-event-handler';
import { WorkflowStepEventHandler } from './workflow-step-event-handler';

export class WorkflowServiceEventListener extends PubSubConnection {
    private static instance: WorkflowServiceEventListener | null = null;
    #workflowEventHandler: WorkflowEventHandler;
    #workflowStepEventHandler: WorkflowStepEventHandler;
    #workflowButtonEventHandler: WorkflowButtonEventHandler;

    private constructor(dispatch: ThunkDispatch<any, any, AnyAction>) {
        super('workflow');
        this.#workflowEventHandler = new WorkflowEventHandler(dispatch);
        this.#workflowStepEventHandler = new WorkflowStepEventHandler(dispatch);
        this.#workflowButtonEventHandler = new WorkflowButtonEventHandler(dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, AnyAction>): WorkflowServiceEventListener {
        if (this.instance == null) {
            this.instance = new WorkflowServiceEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        this.#workflowEventHandler.onMessageEvent(event);
        this.#workflowStepEventHandler.onMessageEvent(event);
        this.#workflowButtonEventHandler.onMessageEvent(event);
    }
}
