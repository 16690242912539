import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Objects = {
    DEFAULT: {
        CREATION_TIMESTAMP: (
            <FormattedMessage
                id='ContactList.Table.Title.CreationTimestamp'
                description='Table title for creation date'
                defaultMessage='Created date'
            />
        ),
        UPDATE_TIMESTAMP: (
            <FormattedMessage
                id='Objects.DEFAULT.UPDATE_TIMESTAMP'
                description='Table title for update date'
                defaultMessage='Updated date'
            />
        )
    },
    RAKINDA: {
        MAC_ADDRESS: (
            <FormattedMessage
                id='Objects.RAKINDA.MAC_ADDRESS'
                description='Label for mac address'
                defaultMessage='Mac address'
            />
        ),
        STATUS: (
            <FormattedMessage
                id='Objects.RAKINDA.STATUS'
                description='Label for status'
                defaultMessage='Status'
            />
        ),
        HEARTBEAT: (
            <FormattedMessage
                id='Objects.RAKINDA.HEARTBEAT'
                description='Label for heartbeat'
                defaultMessage='Heartbeat'
            />
        ),
        MASTER_PIN_CODE: (
            <FormattedMessage
                id='Forms.MASTER_PIN_CODE'
                description='Label for master pin code'
                defaultMessage='Master pin code'
            />
        )
    },
    SLOT: {
        SLOTS: (
            <FormattedMessage
                id='Objects.SLOT.SLOTS'
                description='Message for SLOTs'
                defaultMessage='SLOTs'
            />
        )
    },
    BADGE: {
        NUMBER: (
            <FormattedMessage
                id='Objects.BADGE.NUMBER'
                description='Label for number'
                defaultMessage='Number'
            />
        )
    },
    TEMPLATE: {
        DESCRIPTION: (
            <FormattedMessage
                id='Objects.TEMPLATE.DESCRIPTION'
                description='Label for description'
                defaultMessage='Description'
            />
        )
    }
};
