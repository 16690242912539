import { SelectInput } from '@frontend/basic-forms';
import React from 'react';
import { ActionMeta } from 'react-select';

import useWorkflowStepActionSelect from './workflow-step-action-select.controller';

const ID = 'workflow-step-action-select';
export interface WorkflowStepActionSelectProps {
    required: boolean;
    submitted: boolean;
    onChange: (newValue: { value: string; label: any }, actionMeta: ActionMeta<any>) => void;
    value?: string;
}

export const WorkflowStepActionSelect = (props: WorkflowStepActionSelectProps) => {
    const viewProps = useWorkflowStepActionSelect();

    return (
        <div id={ID}>
            <SelectInput
                label={'Action'}
                submitted={props.submitted}
                required={props.required}
                options={viewProps.options}
                value={props.value}
                onChange={(value, actionMeta) => props.onChange(value as { value: string; label: any }, actionMeta)}
            />
        </div>
    );
};
