import { useEffect, useState } from 'react';

import { ModuleClient } from './api/module-client';
import { Module } from './module';

declare global {
    interface WindowEventMap {
        'useModule:request': CustomEvent<string>;
    }
}

const REQUEST_ACCOUNT_EVENT = 'useModule:request';
const STORAGE_KEY = 'MODS_REQ';

export function moduleRequest(moduleId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_ACCOUNT_EVENT, { detail: moduleId }));
}

interface Props {
    seedStore: (modules: Module[]) => void;
}

export function useModules(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(REQUEST_ACCOUNT_EVENT, listener);
    }, []);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getModules(pending).then((result) => {
                props.seedStore(result);
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };
}

async function getModules(moduleIds: string[]): Promise<Module[]> {
    const response = await ModuleClient.resolveModules(moduleIds);
    return response.results;
}
