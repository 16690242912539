import { HiDeviceTablet } from 'react-icons/hi';

import { DetailModalType } from '../../modals/detail-modal-container/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useRakindaBadge from './rakinda-badge.controller';

export interface RakindaBadgeProps {
    id?: string;
    name?: string;
}

const RakindaBadge = (props: RakindaBadgeProps) => {
    const viewProps = useRakindaBadge(props);

    return (
        <BadgeTemplate
            disabled={viewProps.disabled}
            objectId={props.id}
            type={DetailModalType.RAKINDA}>
            <HiDeviceTablet className='me-1' />
            {!props.name && !viewProps.displayName ? (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            ) : props.name ? (
                props.name
            ) : (
                viewProps.displayName
            )}
        </BadgeTemplate>
    );
};

export default RakindaBadge;
