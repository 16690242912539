import React from 'react';

import { DefaultSelectProps } from '../basic-forms';
import { SelectInput } from '../select-input/select-input.component';
import { countryList } from './data/countries';

export const ID = 'country-input';

export const CountryInput = (props: DefaultSelectProps) => {
    return (
        <SelectInput
            {...props}
            isClearable
            options={countryList.map((c) => ({ label: c.label, value: c.label }))}
            value={props.value}
            onChange={(newValue, actionMeta) => {
                if (props.onChange) props.onChange(newValue ? (newValue as any).value : newValue);
            }}
            submitted={false}
        />
    );
};
