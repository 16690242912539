import { Logger } from '@frontend/Logger';
import { PubSubConnection } from '@frontend/pub-sub';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { Account } from './account';
import { addAccount, removeAccount, updateAccount } from './redux/account-slice';

enum MessageTypes {
    UPDATED = 'account_updated',
    DELETED = 'account_deleted',
    CREATED = 'account_created'
}

export class AccountEventListener extends PubSubConnection {
    private static instance: AccountEventListener | null = null;
    #dispatch: ThunkDispatch<any, any, AnyAction>;
    private constructor(dispatch: ThunkDispatch<any, any, AnyAction>) {
        super('account');
        this.#dispatch = dispatch;
    }

    static getInstance(dispatch: ThunkDispatch<any, any, AnyAction>): AccountEventListener {
        if (this.instance == null) {
            this.instance = new AccountEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: Account; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: Account; changes?: any }) {
        Logger.log('Updating account in store', { account: payload.data.id }, payload.data);
        this.#dispatch(updateAccount(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: Account }) {
        Logger.log('Removing account from store', { account: payload.data.id }, payload.data);
        this.#dispatch(removeAccount(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: Account; changes?: any }) {
        Logger.log('Adding account to store', { account: payload.data.id }, payload.data);
        this.#dispatch(addAccount(payload.data));
    }
}
