import { ClassType } from '@frontend/common';
import { DefaultModal, DefaultModalProps, HorizontalButtonGroup, ModalHeader, ModalType } from '@frontend/elements';
import { Transaction } from '@frontend/transaction';
import { FaEye } from 'react-icons/fa';

import { TransactionDetail } from '../transaction-detail/transaction-detail.component';
import { useTransactionDetailModal } from './transaction-detail-modal.controller';

const ID = 'transaction-detail-modal';

export interface TransactionDetailModalProps extends DefaultModalProps {
    transaction: Transaction;
}

const TransactionDetailModal = (props: TransactionDetailModalProps) => {
    const viewProps = useTransactionDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id='tranaction-detail-modal-header'
                handleClose={props.handleClose}
                title={props.transaction.tracking_number}
            />
            <div className='modal-body'>
                <TransactionDetail transaction={props.transaction} />
            </div>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <HorizontalButtonGroup
                    direction='left'
                    buttons={[
                        {
                            text: 'View details',
                            onClick: () => viewProps.viewDetails(),
                            type: ClassType.PRIMARY,
                            icon: FaEye
                        }
                    ]}
                />
            </div>
        </DefaultModal>
    );
};

export default TransactionDetailModal;
