import React from 'react';
import { LuWorkflow } from 'react-icons/lu';

import useWorkflowBadge from './workflow-badge.controller';

const ID = 'workflow-badge';
export interface WorkflowBadgeProps {
    id?: string;
    accountId?: string;
    name?: string;
}

export const WorkflowBadge = (props: WorkflowBadgeProps) => {
    const viewProps = useWorkflowBadge(props);

    return (
        <span
            id={ID}
            className={`badge badge-secondary ${!viewProps.disabled && 'cursor-pointer'} m-1`}
            onClick={viewProps.onClick}>
            <LuWorkflow className='me-2' />
            {viewProps.displayName || (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </span>
    );
};
