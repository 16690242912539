import { Logger } from '@frontend/Logger';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { addWorkflowButton, removeWorkflowButton, updateWorkflowButton } from '../redux/workflow-button-slice';
import { WorkflowButton } from '../workflow-button';

enum MessageTypes {
    UPDATED = 'workflow_button_updated',
    DELETED = 'workflow_button_deleted',
    CREATED = 'workflow_button_created'
}

export class WorkflowButtonEventHandler {
    #dispatch: ThunkDispatch<any, any, AnyAction>;

    constructor(dispatch: ThunkDispatch<any, any, AnyAction>) {
        this.#dispatch = dispatch;
    }
    public onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: WorkflowButton; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: WorkflowButton; changes?: any }) {
        Logger.log('Updating workflow button in store', {}, payload.data);
        this.#dispatch(updateWorkflowButton(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: WorkflowButton }) {
        Logger.log('Removing workflow button from store', {}, payload.data);
        this.#dispatch(removeWorkflowButton(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: WorkflowButton; changes?: any }) {
        Logger.log('Adding workflow button to store', {}, payload.data);
        this.#dispatch(addWorkflowButton(payload.data));
    }
}
