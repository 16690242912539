import { deleteSpotTransaction } from '@frontend/transaction';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../redux/store';
import { TransactionDetailModalProps } from './transaction-detail-modal.component';

interface ViewProps {
    removeTransaction: () => void;
    showUpdateModal: boolean;
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    viewDetails: () => void;
}

export const useTransactionDetailModal = (props: TransactionDetailModalProps): ViewProps => {
    const dispatch = useAppDispatch();
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const navigate = useNavigate();

    function removeTransaction() {
        if (!props.transaction) return;
        dispatch(deleteSpotTransaction({ spotId: props.transaction.spot_id, transactionId: props.transaction.id }));
    }

    const viewDetails = () => {
        navigate(`/transaction/${props.transaction?.id}/detail`);
    };

    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        removeTransaction,
        showDeleteModal,
        showUpdateModal,
        viewDetails
    };
};
