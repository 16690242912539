import { Rakinda, fetchRakinda } from '@frontend/rakinda';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { RakindaDetailModalWrapperProps } from './rakinda-detail-modal-wrapper.component';

interface ViewProps {
    rakinda?: Rakinda;
}

const useRakindaDetailModalWrapper = (props: RakindaDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const rakindas = useAppSelector((state) => state.rakindas);
    const [rakinda, changeRakinda] = useState<Rakinda | undefined>(undefined);

    useEffect(() => {
        const found = rakindas.unordered.find((r) => r.id == props.id);
        if (found) changeRakinda(found);
        else dispatch(fetchRakinda(props.id));
    }, [props.id, rakindas]);

    return { rakinda };
};

export default useRakindaDetailModalWrapper;
