import { APIClient } from '@frontend/api-utils';

import { WorkflowFieldTemplate } from '../workflow-field-template';

const endpoint = '/workflow-service/api/v1';
export class WorkflowTemplateClient extends APIClient {
    public static async fetchWorkflowActionFieldTemplates(): Promise<{ [key: string]: WorkflowFieldTemplate[] }> {
        const response = await this.fetch(`${endpoint}/workflow-action-field-templates`);
        return await this.handleResponse<{ [key: string]: any[] }>(response);
    }

    public static async fetchWorkflowTriggerFieldTemplates(): Promise<{ [key: string]: WorkflowFieldTemplate[] }> {
        const response = await this.fetch(`${endpoint}/workflow-trigger-field-templates`);
        return await this.handleResponse<{ [key: string]: any[] }>(response);
    }
}
