import { ApiError } from '@frontend/api-utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { WorkflowActionClient } from '../api/workflow-action-client';
import { WorkflowTemplateClient } from '../api/workflow-template-client';
import { WorkflowTriggerClient } from '../api/workflow-trigger-client';
import { WorkflowFieldTemplate } from '../workflow-field-template';

interface WorkflowSharedState {
    actions: { [key: string]: string } | null;
    triggers: { [key: string]: string } | null;

    actionFieldTemplates: { [key: string]: WorkflowFieldTemplate[] } | null;
    triggerFieldTemplates: { [key: string]: WorkflowFieldTemplate[] } | null;
}

const initialState: WorkflowSharedState = {
    actions: null,
    triggers: null,
    actionFieldTemplates: null,
    triggerFieldTemplates: null
};

export const workflowSharedSlice = createSlice({
    name: 'workflowShared',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkflowActions.fulfilled, (state, action) => {
                state.actions = action.payload;
            })
            .addCase(fetchWorkflowTriggers.fulfilled, (state, action) => {
                state.triggers = action.payload;
            })
            .addCase(fetchWorkflowActionFieldTemplates.fulfilled, (state, action) => {
                state.actionFieldTemplates = action.payload;
            })
            .addCase(fetchWorkflowTriggerFieldTemplates.fulfilled, (state, action) => {
                state.triggerFieldTemplates = action.payload;
            });
    }
});

export const fetchWorkflowActionFieldTemplates = createAsyncThunk<{ [key: string]: WorkflowFieldTemplate[] }>(
    'fetchWorkflowActionFieldTemplates',
    async (_, { rejectWithValue }) => {
        try {
            return await WorkflowTemplateClient.fetchWorkflowActionFieldTemplates();
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchWorkflowTriggerFieldTemplates = createAsyncThunk<{ [key: string]: WorkflowFieldTemplate[] }>(
    'fetchWorkflowTriggerFieldTemplates',
    async (_, { rejectWithValue }) => {
        try {
            return await WorkflowTemplateClient.fetchWorkflowTriggerFieldTemplates();
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchWorkflowActions = createAsyncThunk<{ [key: string]: string }>('fetchWorkflowActions', async (_, { rejectWithValue }) => {
    try {
        return await WorkflowActionClient.fetchWorkflowActions();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchWorkflowTriggers = createAsyncThunk<{ [key: string]: string }>('fetchWorkflowTriggers', async (_, { rejectWithValue }) => {
    try {
        return await WorkflowTriggerClient.fetchWorkflowTriggers();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});
