import React from 'react';
import { LuBoxes } from 'react-icons/lu';

import useSpotBadge from './spot-badge.controller';

const ID = 'spot-badge';

export interface SpotBadgeProps {
    id?: string;
    name?: string;
}

export const SpotBadge = (props: SpotBadgeProps) => {
    const viewProps = useSpotBadge(props);

    return (
        <span
            id={ID}
            className={`badge badge-secondary ${!viewProps.disabled && 'cursor-pointer'} m-1`}
            onClick={() => props.id && viewProps.onClick()}>
            <LuBoxes className='me-2' />
            {viewProps.displayName ? (
                viewProps.displayName
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </span>
    );
};
