import { SelectInput } from '@frontend/basic-forms';
import React from 'react';
import { ActionMeta } from 'react-select';

import useModuleTypeSelect from './module-type-select.controller';

const ID = 'module-type-select';
export interface ModuleTypeSelectProps {
    id?: string;
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: { value: string; label: any }, actionMeta: ActionMeta<any>) => void;
    value?: string;
}

export const ModuleTypeSelect = (props: ModuleTypeSelectProps) => {
    const viewProps = useModuleTypeSelect();

    return (
        <SelectInput
            id={props.id || ID}
            className={props.className}
            label={props.label || 'Module type'}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={props.value}
            onChange={(value, actionMeta) => props.onChange(value as { value: string; label: any }, actionMeta)}
        />
    );
};
