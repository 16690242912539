import { Account } from '@frontend/account';
import { ClassType } from '@frontend/common';
import { DefaultModal, DefaultModalProps, ModalFooter, ModalHeader, ModalType } from '@frontend/elements';
import { FaEye } from 'react-icons/fa';

import { AccountDetail } from '../account-detail.component';
import { useAccountDetailModal } from './account-detail-modal.controller';

const ID = 'account-detail-modal';

export interface AccountDetailModalProps extends DefaultModalProps {
    account: Account;
}

const AccountDetailModal = (props: AccountDetailModalProps) => {
    const viewProps = useAccountDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id={`${ID}-header`}
                handleClose={props.handleClose}
                title={'Account'}
            />
            <div className='modal-body'>
                <AccountDetail account={props.account} />
            </div>
            <ModalFooter
                buttons={[
                    {
                        text: 'View details',
                        icon: FaEye,
                        type: ClassType.PRIMARY,
                        onClick: () => viewProps.viewDetails()
                    }
                ]}
            />
        </DefaultModal>
    );
};

export default AccountDetailModal;
