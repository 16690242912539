import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TerminalDetailModalProps } from './terminal-detail-modal.component';

interface ViewProps {
    showUpdateModal: boolean;
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    viewDetails: () => void;
}

export const useTerminalDetailModal = (props: TerminalDetailModalProps): ViewProps => {
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const navigate = useNavigate();

    function viewDetails() {
        navigate(`/terminal/${props.terminal?.id}/detail`);
    }

    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        viewDetails,
        showDeleteModal,
        showUpdateModal
    };
};
