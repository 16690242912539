import { Account, AccountClient, fetchAccounts } from '@frontend/account';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AccountSelectProps } from './account-select.component';

interface ViewProps {
    options: { value: string; label: string }[];
    value?: { value: string; label: string };
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;
}

const pageSize = '100';
const useAccountSelect = (props: AccountSelectProps): ViewProps => {
    const dispatch = useAppDispatch();
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const accountsState = useAppSelector((state) => state.accounts);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const value = useMemo(() => {
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options]);

    useEffect(() => {
        dispatch(fetchAccounts({ index: index.toString(), size: pageSize }));
    }, [index]);

    useEffect(() => {
        if (accountsState.accounts) {
            changeCount(accountsState.accounts.count);
            changeOptions(mapResultSetToOptions(accountsState.accounts.results));
        }
    }, [accountsState.accounts]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            AccountClient.fetchAccounts({ search: inputValue, index: '0', size: pageSize }).then((result) => {
                callback(mapResultSetToOptions(result.results));
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    return {
        options,
        value,
        onScrollToBottom,
        search
    };
};

function mapResultSetToOptions(accounts: Account[]): { value: any; label: string }[] {
    return accounts.map((a) => ({ value: a.id, label: a.name }));
}

export default useAccountSelect;
