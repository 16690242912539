import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContactDetailModalProps } from './contact-detail-modal.component';

interface ViewProps {
    showUpdateModal: boolean;
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    viewDetails: () => void;
}

export const useContactDetailModal = (props: ContactDetailModalProps): ViewProps => {
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const navigate = useNavigate();

    function viewDetails() {
        navigate(`/contact/${props.contact?.id}/detail`);
    }

    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        showDeleteModal,
        showUpdateModal,
        viewDetails
    };
};
