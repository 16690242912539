import { useLocation } from 'react-router-dom';

interface ViewProps {
    isDetailPage: boolean;
}

const useContactDetail = (): ViewProps => {
    const { pathname } = useLocation();

    return {
        isDetailPage: pathname.includes('detail') && pathname.includes('contact')
    };
};

export default useContactDetail;
