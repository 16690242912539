import { DefaultModalProps, LoadingModal, ModalType } from '@frontend/elements';

import BadgeDetailModal from '../badge-detail-modal/badge-detail-modal.component';
import useBadgeDetailModalWrapper from './badge-detail-modal-wrapper.controller';

export interface BadgeDetailModalWrapperProps extends DefaultModalProps {
    id: string;
}

const BadgeDetailModalWrapper = (props: BadgeDetailModalWrapperProps): JSX.Element => {
    const viewProps = useBadgeDetailModalWrapper(props);

    if (!viewProps.badge) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <BadgeDetailModal
            badge={viewProps.badge}
            customWidth={40}
            {...props}
        />
    );
};

export default BadgeDetailModalWrapper;
