import { EventMessage } from '@frontend/event-service';
import { RowAnchor, RowProps } from '@frontend/table';
import React from 'react';
import { Row } from 'react-table';

import { DateBadge } from '../../badge-components/date-badge/date-badge.component';
import { SeverityBadge } from '../../badge-components/severity-badge/severity-badge.component';
import { EventLink } from './event-table.component';

interface EventElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<EventMessage>;
    onClick?: (element: EventMessage) => void;
}

const EventElement = (props: EventElementProps) => {
    return (
        <tr
            id='Event-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>{props.row.original.type}</td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <SeverityBadge severity={props.row.original.level.severity} />
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center text-wrap'>{props.row.original.message}</td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <DateBadge
                    date={props.row.original.timestamp}
                    displayTime
                />
            </td>
            <td>
                <RowAnchor {...props} />
                <EventLink {...props} />
            </td>
        </tr>
    );
};

export default EventElement;
