import { TransactionState } from '@frontend/transaction';

import { TransactionStateBadgeProps } from './transaction-state-badge.component';

interface ViewProps {
    displayName: React.ReactNode;
    type: string;
}

const useTransactionStateBadge = (props: TransactionStateBadgeProps): ViewProps => {
    return {
        displayName: TransactionState.getByValue(props.state)?.displayName || 'Unknown',
        type: TransactionState.getType(props.state)
    };
};

export default useTransactionStateBadge;
